/* Desktop */

.carouselContainer {
    position: relative;
}

.logo {
    // border: 1px solid green;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    img {
        min-width: 150px;
        width: 150px;
    }

    min-width: 200px;
    width: 200px;
    height: 100%;
}

.carouselTrack1,
.carouselTrack2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
}

.carouselTrack1 {
    //first track
    position: absolute;
    animation: scroll1 var(--desktop-animation-duration) linear infinite;
}

.carouselTrack2 {
    //second track
    animation: scroll2 var(--desktop-animation-duration) linear infinite;
}

@keyframes scroll1 {
    0% {
        transform: translateX(var(--desktop-start-position1))
    }

    100% {
        transform: translateX(var(--desktop-end-position1))
    }
}


@keyframes scroll2 {
    0% {
        transform: translateX(var(--desktop-start-position2))
    }

    100% {
        transform: translateX(var(--desktop-end-position2))
    }
}


.carouselContainer:hover .carouselTrack1,
.carouselContainer:hover .carouselTrack2 {
    animation-play-state: paused;
}

/* Mobile */

.carouselContainerMobile {
    display: flex;
    flex-direction: column;
    overflow: hidden !important;
}

.carouselTrack1Mobile,
.carouselTrack2Mobile {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    // gap: 11%;
    min-width: 3600px;
    width: 3600px;
}

.carouselTrack1Mobile {
    // border: 1px solid red;
    animation: scroll1Mobile var(--mobile-animation-duration) linear infinite;
}

.carouselTrack2Mobile {
    // border: 1px solid blue;
    animation: scroll2Mobile var(--mobile-animation-duration) linear infinite;
}

@keyframes scroll1Mobile {
    0% {
        transform: translateX(var(--mobile-start-position1))
    }

    100% {
        transform: translateX(var(--mobile-end-position1))
    }
}

@keyframes scroll2Mobile {
    0% {
        transform: translateX(var(--mobile-start-position2))
    }

    100% {
        transform: translateX(var(--mobile-end-position2))
    }
}

.carouselContainerMobile:hover .carouselTrack1Mobile,
.carouselContainerMobile:hover .carouselTrack2Mobile {
    animation-play-state: paused;
}