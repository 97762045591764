.section-counter {
  background: linear-gradient(to right, #64dbad, #2d53e4);
  //height: 233px;
  width: 100%;
  margin-top: 20px;
  z-index: 10;
  position: relative;
  padding-bottom: 32px;
  @media (max-width: 992px) {
    margin-top: 10px;
    margin-bottom: 60px;
    height: auto;
    padding-bottom: 15px;
    overflow: hidden;
  }

  .title {
    margin-top: 20px;
    font-size: 50px;
    color: white;
    width: 100%;
    text-align: center;
    display: inline-block;
    font-weight: 500;
    margin-bottom: 10px;
    position: relative;
    z-index: 2;

    @media (max-width: 992px) {
      font-size: 25px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  img.decoration {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;

    @media (max-width: 992px) {
      display: none;
    }

  }

  .numbers {
    text-align: center;
    position: relative;
    z-index: 2;

    .number-wrapper.last {
      margin-right: 80px;
      @media (max-width: 992px) {
        margin-right: 0;
      }
    }

    .number-wrapper {
      display: inline-block;
      background: linear-gradient(180deg, #FFFFFF 0%, #E3E3E3 25.35%, #FFFFFF 63.89%, #FFFFFF 100%);
      border-radius: 10px;
      width: 91px;
      //height: 95px;
      text-align: center;
      margin: 0 5px;

      @media (max-width: 992px) {
        width: 28px;
        height: 30px;
        border-radius: 3.5px;
        margin: 0 2px;
      }

      .number {
        padding: 15px 0 4px 0;
        @media (max-width: 992px) {
          padding: 6px 8px;
        }
      }

    }

    .comma {
      display: inline-block;
      width: 38px;
      text-align: center;

      @media (max-width: 992px) {
        width: 10px;
        height: 30px;
      }

      img {
        position: relative;
        bottom: -55px;
        width: 21px;
        margin: 0 auto;

        @media (max-width: 992px) {
          bottom: -17px;
          height: 100%;
          width: auto;
        }
      }
    }

    .shekel {
      margin-right: 34px;
      margin-bottom: 15px;

      @media (max-width: 992px) {
        margin-right: 7px;
        margin-bottom: 6px;
        width: 14px;
      }
    }
  }
}

