@import "./variables";
@import "./grid";

.accordion {
  $background-accordion: #eef2ff;
  div.c {
    position: relative;
    margin-bottom: 14px;
  }
  input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: 0;
  }
  h1 {
    @include paragraph-regular();
    background: $background-accordion;
    color: var(--text-color);
    padding: 22px 30px;
    position: relative;
    @media (max-width: 992px) {
      padding: 13px 15px 13px 13px;
    }
  }
  label::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 12px;
    height: 12px;
    border-bottom: 2px solid var(--text-color);
    border-left: 2px solid var(--text-color);
    transform: rotate(45deg);
    left: 6px;
    top: 8px;
  }
  label {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  div.p {
    background-color: #eef2ff80;
    overflow: hidden;
    max-height: 0px;
    opacity: 0;
    transition: all 0.4s linear;
  }
  div.p p {
    @include paragraph-regular();
    padding: 35px;
  }
  input:checked ~ h1 label::after {
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 12px;
    height: 12px;
    border-bottom: 2px solid var(--text-color);
    border-left: 2px solid var(--text-color);
    transform: rotate(315deg);
    left: 6px;
    top: 8px;
  }
  input:checked ~ h1 ~ div.p {
    max-height: 1000px;
    opacity: 1;
  }
  a {
    color: steelblue;
  }
}
