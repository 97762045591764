.new-tech-container {
    margin-top: 88px;
    font-family: "Heebo", Arial, sans-serif;
    font-style: normal;

    .mobile-only {
        display: none;

        @media (max-width: 992px) {
            display: block;
        }
    }

    .desktop-only {
        display: block;

        @media (max-width: 992px) {
            display: none;
        }
    }
    
    &.h1 {
        margin: 50px 0;
        text-align: justify;
        font-weight: 700;
        font-size: 48px;
        line-height: 64px;

        @media (max-width: 992px) {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            text-align: justify;
            margin: 25px 0;
        }
    }

    .main-content {
        padding-top: 50px;
        max-width: 1400px;
        width: 70%;
        margin: auto;
        color: #000000;
        
        @media (max-width: 992px) {
            padding-top: 10px;
            width: 85%;
        }

        
        .new-tech-container.h2 {
            text-align: right;
            margin: 50px 0 30px 0;
            font-weight: 700;
            font-size: 32px;
            line-height: 43px;

            @media (max-width: 992px) {
                font-weight: 700;
                font-size: 15px;
                line-height: 20px;
                text-align: justify;
                margin: 25px 0 15px 0;
            }
        }
        
        .new-tech-container.p {
            margin: 20px 0;
            text-align: justify;
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;

            span {
                font-weight: 700;
                margin-left: 8px;
            }

            @media (max-width: 992px) {
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                text-align: justify;
                margin: 15px 0;
            }
        }

        img {
            width: 100%;
        }

        div.link-wrapper {
            margin: 30px 0;
            a {
                display: block;
                font-weight: 400;
                font-size: 24px;
                line-height: 32px;
                text-align: justify;
                color: #294FE9;
                text-decoration: none;

                @media (max-width: 992px) {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 20px;
                    text-align: justify;
                }
            }
        }
    }

    .calendar-wrapper {
       
        @media (max-width: 992px) {

            h1 {
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                text-align: justify;
                margin: 6vh 0 3vh 0;
            }

            h3 {
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                text-align: justify;
            }
        }
    }
}
