@import "./variables";
@import "./grid";
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;600;700;800&display=swap");
@mixin backgground-graditn {
  background: linear-gradient(103.48deg, #1b35db -8.94%, #3566f6 107.29%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // font-family: "PTabsolute Sans", sans-serif;
}

.CookieConsent {
  width: 100%;
  background: #e9edff;
  display: inline-flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  opacity: 0.9;
  bottom: 0;
  z-index: 9999;
  min-height: 85px;
  font-size: 14px;
  font-weight: 500;
  line-height: 33px;

  @media (max-width: 992px) {
    //display: none;
    padding-right: 10px;
    padding-left: 10px;
    background: #9baaed;
    line-height: 22px;
    padding-top: 5px;
  }

  .text {
    @media (max-width: 992px) {
      margin-bottom: 25px;
    }
  }

  button#rcc-confirm-button {
    cursor: pointer;
    position: absolute;
    background: #c6d0ff;
    color: var(--box-color);
    font-size: 14px;
    font-weight: 500;
    margin-left: 15px;
    width: 60px;
    height: 32px;
    left: 0px;
    top: 26px;
    border: none;
    &:focus {
      background: #91a3f3;
    }
    &:hover {
      background: #91a3f3;
    }

    @media (max-width: 992px) {

      bottom: 10px;
      top: auto;
    }


  }
  .term-of-use {
    cursor: pointer;
    border: 1px solid var(--box-color);
    box-sizing: border-box;
    position: absolute;
    border-radius: 2px;
    opacity: 0.4;
    font-size: 14px;
    font-weight: 500;
    line-height: 33px;
    height: 32px;
    width: 100px;
    left: 21%;
    top: 26px;
    text-align: center;
    text-decoration: none;
    &:focus {
      background: #b7c3ff;
    }
    &:hover {
      background: #b7c3ff;
    }

    @media (max-width: 992px) {
      top: 104px;
      border: 1px solid black;
      color: black;
      opacity: 0.7;
    }
  }

  button#rcc-decline-button {
    cursor: pointer;
    position: absolute;
    left: 60px;
    top: 28px;
    // margin-right: 426px;
    // padding-left: 20px;
    border: 0;

    svg {
      height: 24px;
      width: 24px;
      color: var(--box-color);
    }

    @media (max-width: 992px) {
      left: 43px;
      top: 107px;
    }
  }
  //p {
  //  position: absolute;
  //  left: 632px;
  //  top: 26px;
  //}
}

:root {
  --text-color: #070c4e;
  --box-color: #070c4e;
  --text-color-green: #3cb989;
}

body {
  background-color: #fff;
  direction: rtl;
  color: var(--text-color);
  font-size: 20px;
  line-height: 1.5;
  font-family: "Heebo", Arial, sans-serif;
}

img {
  max-width: 100%;
  width: auto;
}

.container {
  max-width: 1400px;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 992px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.extra-bold {
  font-size: 20px;
  font-weight: 800;
  line-height: 29px;
  @media (max-width: 992px) {
    font-size: 18px;
    font-weight: bold;
    line-height: 26px;
  }
}
.blue-gradiant {
  background: linear-gradient(
    90.32deg,
    #65dcae 14.65%,
    #3566f6 42.87%,
    #000000 82.43%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.lightble-gradiant {
  /* Gradient1_LightBlue */

  @include backgground-graditn;
}
.large-better {
  @include backgground-graditn;
  font-size: 26;
  font-weight: bold;
}
.small-better {
  @include backgground-graditn;
  font-size: 26;
  font-weight: bold;
  @media (max-width: 992px) {
    font-size: 21px;
  }
}
.small-text-color {
  @include backgground-graditn;
  font-size: 18px;
}
.accessibility-icon {
  height: 46px;
  width: 46px;
  left: 25px;
  top: 23px;
  border-radius: 0px;

  position: absolute;
  z-index: 9999;
  @media (max-width: 1561px) {
    display: none;
  }
}


.ReactModalPortal {
  z-index: 3147483647;
  position: relative;

  .content {
    position: relative;


  }

  .close-modal {
    position: absolute;
    padding: 20px;
    color: #000;
    top: 10px;
    right: 10px;
    z-index: 100;
    cursor: pointer;
    @media (max-width: 992px) {
      right: auto;
      left: 10px;
    }
  }
}

.link {
  text-decoration: underline;
  cursor: pointer;
}
