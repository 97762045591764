@import "../../variables";
@import "../../grid";
.security-and-privacy {
  @include gradient-top();
  margin-top: 88px;
  padding: 100px 15px;
  padding-bottom: 0;

  @media (max-width: 992px) {
    margin-top: 78px;
  }
  .row {
    justify-content: space-between;
  }
  .right-side {
    @media (max-width: 992px) {
      display: none;
    }
    .company-logo {
      display: grid;
      grid-template-rows: repeat(2, 46%);
      grid-template-columns: repeat(2, 47%);
      text-align: right;
      grid-row-gap: 26px;
      grid-column-gap: 30px;
      margin-bottom: 65px;
      .company-logo-1,
      .company-logo-2,
      .company-logo-3,
      .company-logo-4 {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px 0;
        border: 1px solid #3566f6;
        @media (max-width: 992px) {
          display: none;
        }
      }
      .company-logo-3,
      .company-logo-4 {
        img {
          max-width: 120px;
        }
      }
    }
    .security-icon {
      display: flex;
      justify-content: space-between;
      @media (max-width: 992px) {
        display: none;
      }
    }
  }
  .left-side {
    @media (max-width: 992px) {
      padding: 50px 0;
      padding-bottom: 0;
    }
    .title {
      @include title-regular();
      margin-bottom: 38px;
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }
    .company-logo-mobile {
      display: none;
      @media (max-width: 992px) {
        display: grid;
        grid-template-rows: repeat(2, 46%);
        grid-template-columns: repeat(2, 48%);
        text-align: right;
        grid-row-gap: 17px;
        grid-column-gap: 19px;
        margin-bottom: 30px;
      }

      .company-logo-mobile-1,
      .company-logo-mobile-2,
      .company-logo-mobile-3,
      .company-logo-mobile-4 {
        display: none;
        @media (max-width: 992px) {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 102px;
          width: 164px;
          border: 1px solid #3566f6;
        }
      }
    }
    .security-icon-mobile {
      display: none;
      @media (max-width: 992px) {
        display: flex;
        justify-content: space-between;
        padding: 4px;
      }
    }
    .sup-title {
      @include paragraph-regular();
      margin-bottom: 30px;
    }
  }
}
