.aiexpert-container {
    font-family: "Heebo", Arial, sans-serif;
    padding-top: 100px;
    color: #000000;
    direction: rtl;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 992px) {
        padding-top: 100px;
        display: block;
        overflow: hidden;
        width: 100%;
        padding: 100px 15px 0px 15px;
    }

    .main-content {
        max-width: 1350px;
        margin: auto;
        width: 70%;

        display: flex;
        flex-direction: column;

        h1 {
            @media (max-width: 992px) {
                font-size: 23px !important;
            }
        }

        p {
            margin: 20px 0;
            font-size: 20px;
        }

        h6 {
            align-self: center;
            font-weight: normal;
            font-style: italic;
            margin-bottom: 20px;

            @media (max-width: 992px) {
                font-size: 11px;
            }
        }

        h2 {
            margin: 30px 0px 10px 0px;

            @media (max-width: 992px) {
                margin-bottom: 0;
                font-size: 20px;
            }
        }

        img {
            margin-top: 20px;
            align-self: center;
            width: 50%;
            height: auto;

            @media (max-width: 992px) {
                width: 100% !important;
                height: auto;
            }

            @media (max-width: 1600px) {
                width: 60%;
            }
        }

        .clickable-image {
            display: flex;
            flex-direction: column;
            cursor: pointer;
            margin: 15px 0 20px 0;
        }

        a {
            margin: -20px 0;

            @media (max-width: 992px) {
                word-break: break-all;
            }
        }

        .text-blue {
            color: #294FE9;
        }

        .text-orange {
            color: #ffb300;
        }

        .text-purple {
            color: #8800ff;
        }

        .link-wrapper {
            a {
                margin: 0px;
                display: block;
                font-weight: 400;
                font-size: 24px;
                line-height: 32px;
                text-align: justify;
                color: #294FE9;
                text-decoration: none;

                @media (max-width: 992px) {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 20px;
                    text-align: justify;
                }
            }
        }

        @media (max-width: 992px) {
            padding-top: 10px;
            width: 100%;
            max-width: 100%;
            margin: 0;
            padding: 0;

            h1 {
                font-size: 20px;
            }

            p {
                font-size: 15px;
            }

            a {
                font-size: 15px;

            }

        }
    }

    &-calendar-wrapper {
        width: 100%;
        margin: auto;

        .no-good-time {
            display: none;
        }
    }
}