.newTechSm-container {
    font-family: "Heebo", Arial, sans-serif;
    font-style: normal; 

    .mobile-only {
        display: none;

        @media (max-width: 992px) {
            display: block;
        }
    }

    .desktop-only {
        display: block; 

        @media (max-width: 992px) {
            display: none !important;
        }
    }
    
    .content-container {
        padding: 10vh 0;
        width: 64%;
        margin: auto;

        @media (max-width: 992px) {
            width: 85%;
            padding: 8vh 0;
        }
        
        h1 {
            margin-bottom: 3vh;
            font-weight: 700;
            font-size: 48px;
            line-height: 64px;
            text-align: justify;
            
            @media (max-width: 992px) {
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                text-align: center;
            }     

            @media (min-width: 1700px) {
                font-weight: 700;
                font-size: 65px;
                line-height: 75px;
            }
        }
        
        h2 {
            font-weight: 700;
            font-size: 40px;
            line-height: 53px;
            text-align: right;
            
        }

        h2.mini-title {
            font-weight: 700;
            font-size: 36px;
            line-height: 48px;
            text-align: justify;
            margin-bottom: 4vh;

            @media (max-width: 992px) {
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                text-align: justify;
            }
            
        }
        
        h3 {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            text-align: justify;

            @media (max-width: 992px) {
                font-weight: 700;
                font-size: 15px;
                line-height: 20px;
            }
        }
        
        p {
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            text-align: justify;

            @media (max-width: 992px) {
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                text-align: justify;
            }

            @media (min-width: 1700px) {
                font-size: 30px;
                line-height: 50px;
            }
        }

    }

    .schedule-button {
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 16px 24px 16px 24px;
        text-decoration: none;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: #000000;
        outline: none;
        border: none;

        @media (max-width: 992px) {
            padding: 5%;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            width: 55%;
            margin: 8vh auto 0 auto;
        }
    }
    

    .main {
        background-image: url(/images/all/blue-background.png), url(/images/all/better-together.jpg);
        background-size: cover;
        color: #FFFFFF;
        background-origin: content-box;
        background-repeat: no-repeat;
        background-position: center;
        

        @media (max-width: 992px) {
            // background-position: initial;
            background-size: 100% 100%;
        }
        
        .content-container {
            padding: 6vh 0 0 0;
        }

        .link-wrapper {
            padding: 5vh 0 0 0;
            display: flex;
            justify-content: flex-end;
        }

        h1 {
            @media (max-width: 992px) {
                margin: 8vh 0;
            }
        }

        p img {
            margin: 0 10px;
        }

        .top-line {
            margin-bottom: 8vh;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 12vh;

            .right-top {
                display: flex;
                width: 50%;
                align-items: center;

                span {
                    margin-right: 50px;
                }
            }

            .logo {
                width: 160px;
            }
        }
        
        .logo.mobile-only {
            margin: 5vh auto;
            width: 40%;
        }

        .brands {
            display: flex;
            padding: 13vh 0;
            justify-content: flex-end;
            align-items: center;

            @media (max-width: 992px) {
                justify-content: space-between;
                align-items: center;
            }

            .customers-logo  {
                margin: 0 2% 0 0;
                width: 22%;
            }
            
            .teva {
                width: 16%;
            }
            
            div {
                margin: 0 0 0 2%;
                width: 130px;
                font-weight: 400;
                font-size: 20px;
                line-height: 27px;
                text-align: right;

                @media (max-width: 992px) {
                    font-size: 12px;
                    line-height: 17px;
                    width: 90px;
                    margin: 0;
                }
            }
        }
    }

    .video-section {
        .video-wrapper {
            max-width: 1400px;
            width: 82%;
            margin: auto;
            padding-bottom: 45px;
        }
        
        .video-title {
            margin: auto;
            max-width: 1400px;
            width: 82%;
            padding: 32px 0;
            font-weight: 700;
            font-size: 36px;
            line-height: 48px;
            text-align: center;
            letter-spacing: -0.01em;
        }
        
        @media (max-width: 992px) {
            .video-wrapper {
                padding-bottom: 0px;
                width: 100%;
            }
            .video-title {
                text-align: center;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                letter-spacing: -0.08em;
            }
        }
    }

    .howItWorks {
        background: linear-gradient(0deg, #EFF3FF 0%, rgba(239, 243, 255, 0) 100%);
        
        .content-container {
            display: flex;

            @media (max-width: 992px) {
                flex-direction: column;

                img {
                        width: 50%;
                        margin: 3vh auto 5vh auto;
                        min-width: 250px;
                }
    
                h2 {
                    text-align: center;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 32px;
                    margin: 0 0 3vh 0;
                }
            }
        }

        .steps-right {
            width: 32%;
            margin-left: 8%;

            .small-text {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                text-align: justify;
                margin-top: 8px;
            }

            .numbers {
                font-size: 96px;
                line-height: 128px;
                font-weight: 700;
                text-align: right;
                color: rgba(41, 79, 233, 0.2);
                margin-bottom: -28px;
                margin-top: -10px;
            }

            @media (max-width: 992px) {
                
                width: 100%;
                margin-left: 0;

                .steps-wrapper {
                    margin: 2vh 0 7vh 0;
                }

                .steps-wrapper:last-child {
                    margin: 0;
                }
                
                .numbers {
                    display: none;
                }
                
                h3 {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;
                    text-align: justify;
                }
                
                .small-text {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 20px;
                    text-align: justify;
                }
            }
        }
        
        .image-left {
            width: 60%;
        }
        
    }
    
    .customers {
        
        h2.mini-title {
            letter-spacing: -0.01em;
            
            span {
                text-align: right;
                display: inline-block;
                margin: 0 12px;
                color: #294FE9;
            }
            
            @media (max-width: 992px) {
                text-align: center;
                letter-spacing: -0.08em;
                
                span {
                    margin: 0 8px;
                }
            }
              
        }
        
        .savings-wrapper {
            display: flex;
            margin: 60px 0;
            justify-content: space-around;
            
            .savings-item {
                display: flex;
                flex-direction: column;
                align-items: center;

                .savings {
                    color:#294FE9;
                }
                
                .logo-wrapper {
                    height: 80px;
                    display: flex;
                    align-items: center;
                    
                    .customers-logo {
                        max-width: 160px;
                    }
                }
            }

            @media (max-width: 992px) {
                flex-direction: column;
                margin: 8vh 0 0 0;

                .customers-img {
                    width: 50%;
                }
                
                .savings-item {
                    margin-bottom: 8vh;

                    .logo-wrapper {
                        height: 9vh;

                        .customers-logo {
                            max-width: 130px;
                        }
                    }
                }
            }
        }
    }
    
    .action {

        background-color: #294FE9;
        color: #FFFFFF;
        text-align: center;

        h2.mini-title {
            text-align: center;
            margin-bottom: 6vh;
        }

        h3 {
            font-weight: 600;
            font-size: 32px;
            line-height: 43px;
            text-align: center;
            margin-bottom: 6vh;

            span {
                display: block;
                margin-top: 1vh;

                img {
                    margin: 0 10px;
                    width: 140px;
                }
            }

        }

        .schedule-button {
            padding: 16px 90px;

            span {
                display: none;
            }
        }
        
        @media (max-width: 992px) {
            .schedule-button {
                padding: 16px 2%;
                display: block;

                span {
                    display: inline-block;
                    margin: 0 5px;
                }
            }

            h3 {
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                margin-bottom: 8vh;

                span {
                    margin-top: 5vh;

                    img {
                        margin: 0 10px;
                        width: 70px;
                    }
                }
            }
        }     
    }

    .recommended {
        .content-container {

            border-bottom: 1px solid black;

            h2 {
                
                img {
                    margin: 0 10px;
                    width: 140px;
                }
            }

            .recommended-logos {
                display: grid;
                grid-template-columns: 27% 27% 27%;
                grid-column-gap: 9.5%;
                // grid-row-gap: 60px;
                align-items: center;
                justify-items: center;
                justify-content: center;
            }

            @media (max-width: 992px) {

                padding: 8vh 0 10vh 0;

                .recommended-logos {
                    display: grid;
                    grid-template-columns: 27% 27% 27%;
                    grid-column-gap: 9.5%;
                    // grid-row-gap: 4vh;
                    align-items: center;
                    justify-items: center;
                    justify-content: center;
                }

                h2 {
                    letter-spacing: -0.06em;
                    margin-bottom: 7vh;

                    img {
                        margin: 0 5px;
                        width: 80px;
                    }
                }
            }
        }
    }

    .security {
        text-align: center;
        background: linear-gradient(0deg, #EFF3FF 0%, rgba(239, 243, 255, 0) 100%);

        .license-wrapper {
            display: flex;
            margin: 8vh 0;

            .right-license {
                width: 80%;

                .license-item {
                    display: flex;
                    margin-bottom: 10px;
                    align-items: center;

                    p {
                        font-weight: 600;
                    }

                    .image-wrp {
                        max-height: 70px;
                        flex: 0 0 30%;
                        margin-left: 20px;
                        background-color: #FFFFFF;
                        text-align: center;
                        
                        img {
                            max-height: 70px;
                        }
                    }
                }
            }
        }

        .schedule-button {
            background: #294FE9;
            color: #FFFFFF;
            padding: 16px 90px;

            span {
                display: none;
            }
        }

        @media (max-width: 992px) { 
            h2 {
                text-align: center;
                margin: 4vh auto 0 auto;
                font-size: 24px;
                line-height: 32px;
            }
            
            .license-wrapper {
                flex-direction: column-reverse;
                margin: 6vh 0;
                
                .right-license {
                    width: 100%;
                    
                    .license-item {
                        flex-direction: column;
                        margin: 7vh 0;
                        
                        p {
                            text-align: center;
                            margin-top: 1vh;
                            flex: 0 0 70%;
                        }

                        .image-wrp {
                            max-height: 100px;
                            flex: 0 0 30%;
                            max-width: 300px;
                            margin-left: 0;
                        }
                    }

                    .license-item:last-child {
                        margin: 7vh 0 2vh 0;
                    }
                }

                img.mobile-only {
                   width: 50%;
                   margin: auto; 
                }
            }

            .schedule-button {
                padding: 16px 2%;
                display: block;

                span {
                    display: inline-block;
                    margin: 0 5px;
                }
            }
        }
    }

    .faq {

        .faq-container {
            padding: 10vh 0 6vh 0;
            width: 64%;
            margin: auto;

            .question .title {
                font-family: "Segoe UI", Arial, sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 53px;
                text-align: right;
                letter-spacing: -0.02em;
                margin-bottom: 4vh;
            }
            
            @media (max-width: 992px) {
                width: 85%;
                padding: 10vh 0;

                .question .title {
                    font-size: 24px;
                    line-height: 32px;
                }


            }
        }
    }

    .calendar {
        .calendar-wrapper {
            width: 64%;
            margin: auto;

            h1 {
                margin: 0 0 50px 0;
                
                .sm-note {
                    display: block;
                    font-size: 22px;
                    font-weight: 400;
                    color: #294FE9;
                }
            }

            @media (max-width: 992px) {
                width: 85%;

                h1 {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;
                    text-align: justify;
                    margin: 0 0 3vh 0;

                    .sm-note {
                        margin-top: 5px;
                        display: block;
                        font-size: 12px;
                        font-weight: 400;
                        color: #294FE9;
                    }
                }

                h3 {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 20px;
                    text-align: justify;
                }
            }

            .calendar-wide-container{
                display: flex !important;
                justify-content: center !important;
                align-items: center !important;
            }
        }
    }

}
