.calendar-wrapper {
    max-width: 1400px;
    width: 70%;
    margin: auto;
    color: #000000;

    @media (max-width: 992px) {
        padding-top: 0;
        width: 85%;
    }

    .calendar-buttons {
        button {
            height: 50px;
        }
    }

    h1 {
        margin: 50px 0;
        text-align: justify;
        font-weight: 700;
        font-size: 48px;
        line-height: 64px;

        .sm-note {
            display: none;
        }

        @media (max-width: 992px) {
            text-align: right;
            font-weight: 700;
            font-size: 38px;
            line-height: 50px;
        }
    }

    h3 {
        font-weight: 400;
        font-size: 40px;
        line-height: 53px;
        text-align: justify;

        @media (max-width: 992px) {
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 40px;
        }
    }
    
    .calendar-wide-container {
        @media (max-width: 992px) {
            margin-bottom: 100px;
        }
    }
    
}
