@import "../../variables";

img.better-min {
  @include better-min;
}
.howitwork-container {
  width: 100%;
  margin-top: 88px;
  @include gradient-top();
  @media (max-width: 992px) {
    margin-top: 78px;
  }
}
//section presentaion//////////////////////////////////////
.section-presentation {
  .mobile-img {
    display: none;
    @media (max-width: 992px) {
      display: flex;
      margin-bottom: 60px;
    }
  }
  .section-1 {
    padding: 100px 15px;
    @media (max-width: 992px) {
      padding-top: 20px;
      padding-bottom: 30px;
    }
    .right-side {
      @media (max-width: 992px) {
        display: none;
      }
    }

    .left-side {
      padding-top: 121px;
      @media (max-width: 992px) {
        padding-top: 90px;
      }
      .title {
        @include title-regular();
        letter-spacing: 0;
        margin-bottom: 35px;
      }
      .sup-title p {
        @include paragraph-small();
      }
    }
  }
}
//section save//////////////////////////////////
.section-save {
  color: #ffffff;
  background: linear-gradient(103.48deg, #1b35db -8.94%, #3566f6 107.29%);
  .colored-box-Background {
    padding-top: 40px;
    padding-bottom: 80px;
    background-image: url("/images/all/Howitwork-Section2-Background.svg");
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: contain;
    width: 100%;
    @media (max-width: 992px) {
      background: none;
      padding-top: 38px;
      padding-bottom: 53px;
    }
  }
  .right-side {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    .title {
      @include title-regular();
      margin-bottom: 40px;
      @media (max-width: 992px) {
        margin-bottom: 30px;
      }
    }
    .sup-title {
      max-width: 550px;
      @include paragraph-regular();
      margin-bottom: 60px;
      @media (max-width: 992px) {
        margin-bottom: 30px;
        line-height: 26.44px;
      }
    }
    .zoom-button-gray {
      @include zoom-button-gray("/images/all/Dark-Video-icon.png");
      @media (max-width: 992px) {
        margin-bottom: 62px;
      }
    }

    .zoom-button-gray:hover {
      color: white;
      @include zoom-button-gray("/images/all/White-Video-icon.png");
    }
  }
  .left-side {
    text-align: left;
  }
}
//section security and privacy/////////////////////////////////////
.section-security-and-privacy {
  padding: 100px 15px;
  @media (max-width: 992px) {
    padding: 60px 15px;
    padding-bottom: 30px;
  }

  .row {
    justify-content: space-between;
  }
  .right-side {
    @media (max-width: 992px) {
      display: none;
    }
    .company-logo {
      display: grid;
      grid-template-rows: repeat(2, 46%);
      grid-template-columns: repeat(2, 48%);
      text-align: right;
      grid-row-gap: 23px;
      grid-column-gap: 20px;
      margin-bottom: 65px;
      .company-logo-1,
      .company-logo-2,
      .company-logo-3,
      .company-logo-4 {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px 15px;
        border: 1px solid #3566f6;
        @media (max-width: 992px) {
          display: none;
        }
      }
      .company-logo-3,
      .company-logo-4 {
        img {
          max-width: 120px;
        }
      }
    }
    .security-icon {
      display: flex;
      justify-content: space-between;
      @media (max-width: 992px) {
        display: none;
      }
    }
  }
  .left-side {
    .title {
      @include title-regular();
      margin-bottom: 38px;
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }
    .company-logo-mobile {
      display: none;
      @media (max-width: 992px) {
        display: grid;
        grid-template-rows: repeat(2, 50%);
        grid-template-columns: repeat(2, 50%);
        text-align: right;
        grid-row-gap: 17px;
        grid-column-gap: 19px;
        margin-bottom: 30px;
      }
      .company-logo-mobile-1,
      .company-logo-mobile-2,
      .company-logo-mobile-3,
      .company-logo-mobile-4 {
        display: none;
        @media (max-width: 992px) {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px 0;
          border: 1px solid #3566f6;
        }
      }
    }
    .security-icon-mobile {
      display: none;
      @media (max-width: 992px) {
        display: flex;
        justify-content: space-between;
        padding: 4px;

        img {
          width: 80%;
        }
      }
    }
    .sup-title {
      @include paragraph-regular();
      margin-bottom: 30px;
    }
  }
}
// setion commit //////////////////////
.section-commit {
  background: #eef2ff;
  padding: 80px 0;
  @media (max-width: 992px) {
    padding: 55px 0;
  }

  .right-side {
    .title {
      @include title-regular();
      letter-spacing: 0em;
      margin-bottom: 60px;
      min-width: 710px;
      @media (max-width: 992px) {
        min-width: 0;
        margin-bottom: 34px;
      }
    }
    .sup-title {
      @include paragraph-regular();
      margin-bottom: 10px;
      padding-left: 95px;
      @media (max-width: 992px) {
        padding-left: 0;
        margin-bottom: 40px;
      }
      br.mobile-brake {
        display: none;
        @media (max-width: 992px) {
          display: block;
        }
      }
    }
  }
  .left-side {
    text-align: left;
    img.web {
      display: flex;
      @media (max-width: 992px) {
        display: none;
      }
    }
    img.mobile {
      display: none;
      @media (max-width: 992px) {
        display: block;
      }
    }
  }
}
//Q&A////////////////////////////////////////////////////////@at-root
.section-q-and-a {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 992px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .title {
    @include title-regular();
    letter-spacing: none;
    margin-bottom: 74px;
    @media (max-width: 992px) {
      margin-bottom: 35px;
    }
  }
}
