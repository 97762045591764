@import "../../_variables.scss";
@import "../../_grid.scss";
.gradient-top {
  @include gradient-top();
}
.Aboutus-container {
  //background-image: url("/images/all/team.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-position-x: 0em;
  background-size: 48% auto;
  padding: 130px 0;
  margin-top: 88px;
  @media (max-width: 992px) {
    background-image: none;
    padding-bottom: 30px;
    margin-top: 78px;
  }
  .how-we-are {
    .left-side {
      @media (max-width: 992px) {
        display: none;
      }
      img {
        position: absolute;
        top: 88px;
        left: 0;
        width: 45%;
      }
    }
    .right-side {
      //max-width: 665px;
      padding-left: 15px;
      .title {
        @include title-regular();
        margin-bottom: 70px;
        @media (max-width: 992px) {
          margin-bottom: 25px;
        }
      }
      .sup-title {
        @include paragraph-regular();
        margin-bottom: 60px;
        p {
          padding-bottom: 40px;
        }
        span {
          font-weight: 800;
        }
        a {
          color: #1b35db;
          font-weight: 800;
        }
        .mobile-img {
          display: none;
          @media (max-width: 992px) {
            margin-right: -35px;
            display: flex;
            margin-bottom: 40px;
          }
        }
        .mobile-brake {
          display: none;
          @media (max-width: 992px) {
            display: flex;
          }
        }
      }
    }
  }
  .members {
    margin-bottom: 160px;
    @media (max-width: 992px) {
      margin-bottom: 15px;
    }
    .col-3 {
      margin-left: 80px;
      @media (max-width: 992px) {
        margin-bottom: 40px;
      }
    }

    h2 {
      @include paragraph-large();
      font-weight: bold;
    }
    h3 {
      @include paragraph-regular();
      color: #1b35db;
    }
    p {
      @include paragraph-small();
      padding-top: 20px;
    }
  }
}
