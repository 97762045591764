@import "../../../Accordion";

.qanda-container {
  @include gradient-top();
  margin-top: 88px;
  @media (max-width: 992px) {
    margin-top: 78px;
  }

  .question {
    padding-top: 130px;
    padding-bottom: 130px;
    @media (max-width: 992px) {
      padding-left: 35px;
      padding-right: 35px;
      padding-bottom: 25px;
    }

    .title {
      @include title-regular();
      margin-bottom: 70px;
      @media (max-width: 992px) {
        padding-left: 80px;
        margin-bottom: 25px;
      }
    }

  }
}
