$mobile-width: "(max-width: 992px)";
$xs-mobile-width: "(max-width: 355px)";
$sm-mobile-width: "(min-width: 355px) and (max-width: 365px)";
$md-mobile-width: "(min-width: 365px) and (max-width: 375px)";
$lg-mobile-width: "(min-width: 375px) and (max-width: 399px)";
$xl-mobile-width: "(min-width: 400px) and (max-width: 420px)";
$xxl-mobile-width: "(min-width: 420px) and (max-width: 450px)";
$sm-tablet-width: "(min-width: 600px) and (max-width: 780px)";
$md-tablet-width: "(min-width: 780px) and (max-width: 992px)";
$sm-laptop-width: "(min-width: 992px) and (max-width: 1400px)";
$md-laptop-width: "(min-width: 1400px) and (max-width: 1700px)";
$lg-mobile-height: "(min-height: 820px) and (max-height: 870px)";
$xl-mobile-height: "(min-height: 870px)";
$destop-width: "(min-width: 992px)";


.thank-you-page {

    // Section 1
    .welcome-container {
        padding: 245px 450px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: linear-gradient(144deg, #1A39DA 51%, #5AE3CF 100%);
        color: white;
        min-height: 740px;

        @media #{$md-laptop-width} {
            padding: 150px 250px;
        }

        @media #{ $mobile-width } {
            // padding: 100px 50px;
            flex-direction: column;
            gap: 50px;
            text-align: right;
            padding: 150px 40px;
        }
    }

    .icon-container {
        margin-bottom: 20px;

        @media #{$mobile-width} {
            display: none;
        }
    }

    .text-container h1 {
        font-size: 60px;
        font-weight: 600;
        margin-bottom: 10px;
        line-height: 70px;

        @media #{ $mobile-width } {
            font-size: 34px;
            line-height: 43px;
            margin-bottom: 30px;

        }
    }

    .text-container p {
        font-size: 24px;
        line-height: 35px;

        @media #{ $mobile-width } {
            font-weight: 300;
        }
    }


    // Section 2
    .process-steps {
        padding: 75px 450px;
        text-align: center;
        background-color: #f7f7f7;
        color: #070C4E;

        @media #{$md-laptop-width} {
            padding: 150px 250px;
        }

        @media #{$mobile-width} {
            padding: 50px 40px 100px 40px;
            text-align: right !important;
            background: linear-gradient(180deg, #FFFFFF 0%, #EEF2FF 50%);
        }


        .title {
            font-weight: 700;
            font-size: 55px;
            line-height: 73px;
            height: 80px;

            @media #{$mobile-width} {
                height: auto;
                line-height: 43px;
                font-size: 36px !important;
                padding-bottom: 10px;
                text-align: right !important;
            }

            .highlight {
                background: linear-gradient(to right, #66DFAD 0%, #1A39DA 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }


        .description {
            height: 80px;
            font-size: 20px;
            line-height: 30px;

            @media #{$mobile-width} {
                height: auto;
                margin-bottom: 20px;
            }
        }
    }

    .steps-container {
        display: flex;
        gap: 30px;

        @media #{$mobile-width} {
            flex-direction: column;
            padding-right: 20px;
        }
    }

    .step {
        max-width: 250px;
        text-align: right;

        @media #{$mobile-width} {
            margin-bottom: 30px;
        }
    }

    .step-number {
        font-size: 80px;
        line-height: 118px;
        font-weight: bold;
    }

    .arrow {
        margin: 33px 0 0 40px;
        font-size: 36px;

        @media #{$mobile-width} {
            display: none;
        }
    }

    .gradient-text {
        background: linear-gradient(158.3deg, #66DFAD 50%, #1A39DA 80%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .step-title {
        font-size: 24px;
        font-weight: bold;
    }

    .step-description {
        font-size: 20px;
    }


    // Section 3
    .testemonial-container {
        padding: 0px 200px;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 150px;
        background: linear-gradient(to bottom, #f7f7f7 40%, white 60%);

        video {
            width: 50%;
            border-radius: 10px;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
            -moz-tap-highlight-color: transparent;
            -o-tap-highlight-color: transparent;
            tap-highlight-color: transparent;
            -moz-tap-highlight-color: transparent;
            -webkit-tap-highlight-color: transparent;

            @media #{$mobile-width} {
                width: 100vw;
                border-radius: 0px;
            }
        }

        @media #{$mobile-width} {
            padding: 0px 20px;
            gap: 50px;

            img {
                width: 100vw;
                max-width: fit-content;
            }
        }

        .title-container {
            text-align: center;
            font-size: 55px;
            line-height: 75px;
            font-weight: 400;
            letter-spacing: -0.02em;

            @media #{$mobile-width} {
                font-size: 24px;
                line-height: 34px;
            }
        }

        .title-text-light {}

        .title-text-bold {
            background: linear-gradient(to left, #1A39DA 50%, #5AE3CF 110%);
            font-weight: bold;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;

            @media #{$mobile-width} {
                margin-bottom: 10px;
            }
        }
    }

    .companies-container {
        background: white;
        margin: 0;
        padding: 0px 250px 100px 0px;

        @media #{$md-laptop-width} {
            margin-left: 20px;
        }

        @for $i from 1 through 10 {
            .row#{$i} {
                display: flex;
                gap: 50px;
                margin-top: 50px;

                img {
                    width: 10vw;
                    height: 10vw;
                }
            }
        }

        @media (max-width: 992px) {
            padding: 0;
            padding-top: 30px;
            padding-bottom: 30px;

            @for $i from 1 through 10 {
                .row#{$i} {
                    display: flex;
                    gap: 20px;
                    margin-top: 30px;

                    img {
                        width: 25vw;
                        height: 25vw;
                    }
                }
            }
        }
    }

    // Section 4
    .security-compliance-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f7f7f7;
        padding: 40px 20px;
        min-height: 430px;

        @media #{$mobile-width} {
            flex-direction: column;
            gap: 20px;
            padding: 40px 20px;
        }
    }

    .security-compliance {
        display: flex;
        align-items: flex-end;
        gap: 130px;

        @media #{$mobile-width} {
            flex-direction: column;
            gap: 20px;
            align-items: center;
        }
    }

    .title {
        font-size: 55px;
        font-weight: bold;
        color: #070C4E;
        margin-bottom: 20px;

        @media #{$mobile-width} {
            font-size: 30px;
            margin-bottom: 10px;
            text-align: center;
            font-size: 24px !important;
        }
    }

    .text-and-stamps {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;

        @media #{$mobile-width} {
            gap: 20px;
        }
    }

    .images-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 130px;
        margin-bottom: 20px;

        @media #{$mobile-width} {
            flex-direction: column;
            gap: 30px;

            span {
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                margin-bottom: 25px;
            }

            .slightly-up {
                margin-top: -7px;
                margin-bottom: 5px;
            }
        }
    }

    .icon {}

    .lock-container {
        display: flex;
        justify-content: center;
        align-items: center;

        @media #{$mobile-width} {
            gap: 10px;

            img {
                width: 90px;
            }
        }
    }

    .lock-icon {}


    // Section 5
    .q-and-a-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15vh;
        padding: 100px 150px;
        min-height: 80vh;
        background: linear-gradient(360deg, #1A39DA -0%, #7DFCE9 100%);
        min-height: 150vh;

        @media (max-width: 992px) {
            padding: 0 20px;
            min-height: 120vh;
            gap: 8vh;
        }

        .faq-container {
            display: flex;

            @media (max-width: 992px) {
                flex-direction: column;
                text-align: center;
                padding-top: 55px;
                width: 105%;
            }
        }

        &-title {
            color: #070C4E;
            font-weight: 800;
            font-size: 60px;
            width: 50%;
            margin-right: 30px;

            @media (max-width: 992px) {
                font-size: 30px;
                width: 100%;
                margin-right: 0px;
                margin-bottom: 40px;
            }

            @media #{$md-laptop-width} {
                margin-right: 0;
            }
        }

        &-faq {
            width: 90%;
            margin-left: 10%;

            @media (max-width: 992px) {
                width: 100%;
            }

            @media #{$md-laptop-width} {
                margin-left: 0%;
            }
        }

        &-button-container {
            &-button {
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: "Heebo", Arial, sans-serif !important;
                background: white;
                font-size: 24px;
                border-radius: 40px;
                color: #1a39da;
                border: none;
                font-weight: 500;
                cursor: pointer;
                width: 350px;
                height: 64px;
                outline: none !important;
                -webkit-tap-highlight-color: transparent;
                -moz-tap-highlight-color: transparent;
                -o-tap-highlight-color: transparent;
                tap-highlight-color: transparent;
                -moz-tap-highlight-color: transparent;
                -webkit-tap-highlight-color: transparent;


                &:focus {
                    background: #042abb;
                    color: white;
                    outline: none !important;
                    box-shadow: none !important;
                    background-color: inherit !important;
                    webkit-box-shadow: none !important;
                    -moz-box-shadow: none !important;
                }

                &:hover {
                    background: #042abb;
                    color: white
                }


                @media (max-width: 992px) {
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }
    }


    // Footer
    .footer {
        height: 30vh;
        background-color: rgba(26, 57, 218, 1);
        color: white;
        display: flex;

        @media (max-width: 992px) {
            height: 100%;
            min-height: 100vh;
            line-height: 25px;
        }

        @media #{$mobile-width} and #{$xl-mobile-height} {
            min-height: 80vh;
        }

        @media #{$mobile-width} and #{$lg-mobile-height} {
            min-height: 70vh;
        }


        &-body {
            width: 100%;

            @media (min-width: 1600px) {
                width: 80%;
            }

            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 992px) {
                flex-direction: column;
                gap: 60px;
                margin-top: 90px;
                margin-bottom: 60px;
                margin-left: 40px;
                margin-right: 40px;
            }

            &-better {
                p {
                    margin-top: 10px;
                    // margin-bottom: -5px;
                    font-size: 16px;
                    font-weight: bold;
                }
            }

            &-license {
                @media (max-width: 992px) {
                    width: 100%;
                }

                span {
                    font-size: 22px;
                    font-weight: 600;

                    @media (max-width: 992px) {
                        font-size: 20px;
                    }
                }

                ul {
                    list-style: none;

                    li {
                        font-size: 16px;
                        margin-top: 3px;
                    }

                    @media (max-width: 992px) {
                        margin-top: 5px;

                        li {
                            font-size: 14px;
                            margin-bottom: 5px;
                        }
                    }
                }

                @media (max-width: 992px) {
                    order: 3;
                }
            }

            &-better {
                text-align: center;
                font-size: 22px;

                @media (max-width: 992px) {
                    order: 1;
                    margin-bottom: -5px;

                    img {
                        width: 60%;
                        height: 60%;
                    }
                }
            }

            &-sm {
                @media (max-width: 992px) {
                    order: 5;
                }

                display: flex;
                flex-direction: column;

                &-icons {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 30px;
                    margin-bottom: 10px;
                }

                &-text {
                    font-size: 16px;
                    text-align: center;

                    @media (max-width: 992px) {
                        font-size: 12px;
                    }

                    a {
                        color: white;
                        text-decoration: none;
                        transition: color 0.3s ease;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}