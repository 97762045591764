@import "../../variables";
@import "../../grid";

$header-background: #e9edff;
.header-background {
  background: $header-background;
  //position: relative;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
}

.header-container {
  display: flex;
  justify-self: center;
  color: black;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 16px 0;
  min-height: 88px;
  font-size: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  @media (max-width: 992px) {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 20px;
    padding-right: 60px;
    z-index: 999;
    background: $header-background;
    min-height: 78px;
  }

  .social-media-wrap {
    display: none;
    @media (max-width: 992px) {
      display: flex;
      justify-content: center;

      .social-icons a {
        margin: 22px;
        color: var(--text-color);
      }
    }
  }

  .header-button-wrapper {
    @media (max-width: 992px) {
      display: none;
    }
  }

  .left-side-mobile {
    display: none;
    @media (max-width: 992px) {
      display: flex;
      align-items: center;
      gap: 25px;
    }
  }

  img.mobile-video-icon {
    display: none;
    @media (max-width: 992px) {
      display: block;
      width: 46px;
      height: 46px;
      &:focus {
        content: url("/images/all/mobile-Video-icone-click.png");
      }
      &:hover {
        content: url("/images/all/mobile-Video-icone-click.png");
      }
    }
  }
}

img.header-button-right {
  @media (max-width: 992px) {
    width: 120px;
    height: 23;
  }
}

.header-button-wrapper {
  flex: 0 0 auto;
}

.header-button {
  @include header-button("/images/all/Header-Video-icone.png");
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  width: auto;
  justify-content: space-evenly;
  width: 100%;
}

.nav-links {
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 17px;
  font-weight: 500;

  &.active {
    color: #1b35db;
    text-decoration: underline;
    font-weight: 700;
  }
}

.nav-links-mobile {
  display: none;
}

@media (max-width: 992px) {
  .HeaderItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    top: 78px;
    height: calc(100% - 78px);
    opacity: 1;
    // transition: all 0.5s ease;
    overflow: auto;

    [lang="en"] & {
      right: -100%;
    }

    [lang="he"] & {
      left: -100%;
    }
  }

  .nav-menu.active {
    background: #fff;
    left: 0;
    opacity: 0.96;
    // transition: all 0.5s ease;
    z-index: 99;

    [lang="en"] & {
      right: 0%;
    }

    [lang="he"] & {
      left: 0%;
    }

    animation: fadein 250ms;
    -moz-animation: fadein 250ms; /* Firefox */
    -webkit-animation: fadein 250ms; /* Safari and Chrome */
    -o-animation: fadein 250ms; /* Opera */
  }

  .nav-links {
    text-align: center;
    width: 100%;
    display: table;
    font-size: 25px;
    font-weight: 700;
    line-height: 37px;
    color: var(--text-color);

    &.active {
      color: #1b35db;
      text-decoration: none;
      font-weight: 700;
    }
  }

  .nav-links:hover {
    background-color: #fff;
    border-radius: 0;
  }

  .header-button {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
    @media (max-width: 992px) {
      display: none;
    }
  }

  .menu-icon {
    display: none;
    @media (max-width: 992px) {
      display: contents;

      font-size: 1.8rem;
      transform: unset;
      cursor: pointer;
      position: unset;

      svg {
        color: var(--text-color);
        width: 23px;
        height: 32px;
        display: block;
        position: unset;
      }
    }
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
