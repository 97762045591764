@mixin footer-text() {
  font-size: 18px;
  font-weight: 500;
  line-height: 33px;
  color: var(--text-color);
  @media (max-width: 992px) {
    font-size: 14px;
  }
}
@mixin no-mobile {
  @media (max-width: 992px) {
    display: none;
  }
}

.footer-container {
  display: flex;
  flex-direction: column;
  background: #e9edff;
  width: 100%;
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: blue;
    }
  }
  .none-mobile {
    @include no-mobile();
  }
}
// top side of the footer
.footer-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 80px 40px;
  @media (max-width: 992px) {
    padding: 40px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  //logo

  .footer-logo {
    margin-bottom: 50px;
    @media (max-width: 992px) {
      margin-bottom: 25px;
      text-align: center;
    }
  }
  .footer-column {
    @include footer-text();

    .none-mobile {
      @include no-mobile();
    }
  }
  .footer-info-wrap {
    @media (max-width: 992px) {
      margin: 40px 0px;
      margin-bottom: 0; ////containter have the 40 px bottom
    }
  }
  .footer-links-title,
  .footer-info-title {
    @include footer-text();
    margin-bottom: 31px;
    font-weight: bold;
    @media (max-width: 992px) {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 21px;
    }
  }
  .footer-column-logo {
    a {
      @include footer-text();
      display: flex;
      margin-bottom: 26px;
      font-weight: 700;
      &.none-mobile {
        @include no-mobile();
      }
    }
  }
  .footer-column {
    a {
      @include footer-text();
      display: flex;
      margin-bottom: 26px;
    }
    &.f-t a {
      @media (max-width: 992px) {
        font-size: 14px;
        font-weight: 500;
        line-height: 33px;
        margin-bottom: 8px;
        margin-left: 41px;
      }
      display: flex;
      align-items: center;
      img {
        padding-left: 13px;
        max-width: 29px;
      }
    }

    &.f-t span {
      @media (max-width: 992px) {
        font-size: 14px;
        font-weight: 500;
        line-height: 33px;
        margin-bottom: 8px;
        margin-left: 41px;
      }
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 33px;
      color: var(--text-color);
      margin-bottom: 26px;
      img {
        padding-left: 13px;
        max-width: 29px;
      }
    }
  }

  //social media
  .social-media-wrap {
    @include no-mobile();
    .social-icons a {
      margin: 22px;
    }
  }
  hr.brak-line {
    display: none;
    @media (max-width: 992px) {
      display: flex;
      position: absolute;
      width: 100%;
      left: 0;
    }
  }
}
//social media mobile
.social-media-mobile {
  display: none;
  @media (max-width: 992px) {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: auto;
    margin-top: 40px;
    .social-icons-mobile a {
      margin: 22px;
      color: var(--text-color);
    }
  }
}

//bottom side of the footer
.footer-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 27px 0;
  @media (max-width: 992px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .footer-bottom-items a {
    margin: 0px 40px;
    @include footer-text();
    font-weight: 400;
  }

  .footer-bottom-items span {
    margin: 0px 15px;
    @include footer-text();
    font-weight: 400;
    cursor: pointer;
  }
}
.dot {
  border-radius: 0px;
  font-size: 28px;
  color: var(--text-color);
  @media (max-width: 992px) {
    font-size: 14px;
    border-radius: 0px;
  }
}
.footer-bottom-items-mobile {
  display: none;
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 33px;
  }
  .footer-bottom-items-mobile a {
    display: none;
    @media (max-width: 992px) {
      display: contents;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 33px;
    }
  }

  .dot-mobile {
    display: none;
    border-radius: 0px;
    font-size: 28px;
    color: var(--text-color);
    @media (max-width: 992px) {
      display: initial;
      font-size: 14px;
      border-radius: 0px;
      margin: 0 10px;
    }
  }
}
.ReactModalPortal {
  z-index: 3147483647;
  //position: fixed;
  //overflow-y: auto;

  .modal {
    padding: 40px 20px 0 20px;
    background: white;
    width: 50%;
    right: 25%;
    position: absolute;

    @media (max-width: 992px) {
      padding: 0;
      width: 100%;
      right: auto;
      height: 100%;
      background: white;
    }


    .content {
      position: relative;


        @media (max-width: 992px) {
          padding-top: 30px;
          padding-left: 6%;
          background: white;
        }



      .close-modal {
        position: absolute;
        padding: 20px;
        color: #000;
        top: -20px;
        right: -20px;
        cursor: pointer;
      }
    }
  }
}

.rights {
  width: 100%;
  text-align: center;
}

