#zoom {
  padding: 0 15%;

  @media (max-width: 992px) {
    padding: 0;
  }
}

.calendar-container .calendar-information {
  margin-top: 30px;

  p {
    margin-bottom: 30px;
  }
}

.no-good-time {
  font-size: 16px;
  margin-bottom: 20px;

  .click {
    color: rgb(0, 105, 255);
    cursor: pointer;
    text-decoration: underline;
  }
}

.calendar-buttons {
  width: 70%;
  display: flex;
  margin-bottom: 80px;

  @media (max-width: 992px) {
    width: 100%;
  }

  button {
    margin: 0 1%;
    flex-grow: 1;
    cursor: pointer;
    background-color: var(--primary-color-level4, rgba(0, 105, 255, 0.065));

    font-size: 12px;
    //font-weight: 700;
    border-radius: 2px;
    padding: 6px 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: rgb(0, 105, 255);
    text-decoration: none;
    border: none;
  }

  button:hover {
    color: white;
    background-color: rgb(0, 105, 255);
  }
}

.mobile-img {
  display: none;

  @media (max-width: 992px) {
    display: flex;
  }
}

.Calendar {}