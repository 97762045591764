.accordion div.c {
    position: relative;
    margin-bottom: 20px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.accordion input {
    position: absolute;
}

.accordion h1 {
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    background: white;
    color: #070C4E;
    padding: 22px 30px;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    -moz-tap-highlight-color: transparent;
    -o-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -moz-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent;
    outline: none !important;
    box-shadow: none !important;
    webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;

    @media (max-width: 768px) {
        font-size: 16px !important;
    }
}

@media (max-width: 992px) {
    .accordion h1 {
        padding: 13px 13px 13px 13px;
    }
}

.accordion label::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 15px;
    height: 15px;
    border-bottom: 4px solid #1A39DA;
    border-left: 4px solid #1A39DA;
    border-radius: 2px 0px 2px 0px;
    transform: rotate(45deg);
    left: 6px;
    top: 8px;
}


.accordion label {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.accordion div.p {
    background-color: white;
    overflow: hidden;
    max-height: 0px;
    opacity: 0;
    transition: all 0.2s linear;
}

.accordion div.p p {
    font-size: 22px;
    line-height: 35px;
    padding: 35px;
    color: var(--Dark-Blue, #070C4E);
    text-align: right;
    font-family: Heebo;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;

    /* 159.091% */
    @media (max-width: 768px) {
        font-size: 16px;

        .accordion h1 {
            font-weight: 600;
        }
    }
}

.accordion input:checked~h1 label::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 15px;
    height: 15px;
    border-bottom: 4px solid #1A39DA;
    border-left: 4px solid #1A39DA;
    border-radius: 2px 0px 2px 0px;
    transform: rotate(315deg);
    left: 6px;
    top: 8px;
}

.accordion input:checked~h1~div.p {
    transition: max-height 0.2s linear 0s;
    max-height: 1000px;
    transition: all 0.2s linear 0s;
    opacity: 1;
    margin-top: -20px;

}

.accordion a {
    color: steelblue;
}

@media (max-width: 768px) {
    .accordion span {
        font-size: 16px;
    }

    .accordion span::after {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 15px;
        height: 15px;
        border-bottom: 4px solid #1A39DA;
        border-left: 4px solid #1A39DA;
        border-radius: 2px 0px 2px 0px;
        transform: rotate(45deg);
        left: 6px;
        top: 8px;
    }


    .accordion span {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .accordion input:checked~h1 span::after {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 15px;
        height: 15px;
        border-bottom: 4px solid #1A39DA;
        border-left: 4px solid #1A39DA;
        border-radius: 2px 0px 2px 0px;
        transform: rotate(315deg);
        left: 6px;
        top: 8px;
    }

}