.row {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
}

.col {
  flex: 1 1 0;
}

.col-12 {
  width: 100%;
}
.col-11 {
  width: 91.6666%;
}
.col-10 {
  width: 83.3333%;
}
.col-9 {
  width: 75%;
}
.col-8 {
  width: 66.6667%;
}
.col-7 {
  width: 58.3333%;
}
.col-6 {
  width: 50%;
}
.col-5 {
  width: 41.6667%;
}
.col-4 {
  width: 33.3333%;
}
.col-3 {
  width: 25%;
}
.col-2 {
  width: 16.6667%;
}
.col-1 {
  width: 8.3333%;
}

.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
  @media (max-width: 992px) {
    width: 100%;
  }
}

//fade in animation
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
