.whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 64px;
    height: 64px;
    cursor: pointer;

    @media (max-width: 767px) {
        width: 50px;
        height: 50px;
        bottom: 20px;
        right: 10px;
    }
}