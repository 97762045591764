@import "../../variables";

.companies-container {
  width: 100%;
  margin-top: 88px;

  @media (max-width: 992px) {
    margin-top: 78px;
  }

  @include gradient-top();

  .section-presentation {
    background-image: url("/images/all/Companies-Section1-Background.png");
    background-repeat: no-repeat;
    background-position: left top;
    background-position-x: 3em;
    background-size: 50% auto;
    background-size: calc(50% - 3em) auto;
    padding-bottom: 300px;

    @media (max-width: 992px) {
      background-image: none;
      min-height: initial;
      padding-bottom: 0;
      padding-top: 50px;
      padding-bottom: 40px;
    }

    .mobile-img-1 {
      display: none;

      @media (max-width: 992px) {
        display: flex;
      }
    }

    .right-side {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: start;
      justify-content: space-between;
      padding-top: 80px;

      br.web-break {
        display: none;
      }

      @media (max-width: 992px) {
        padding-top: 0;
      }

      .title {
        @include title-large();
        margin-bottom: 35px;

        .g1 {
          background: linear-gradient(89.85deg,
              #65dcae 14.55%,
              #3566f6 73.07%,
              #000000 155.11%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;

          @media (max-width: 992px) {
            font-size: 40px;
          }

          @media (max-width: 400px) {
            font-size: 33px;
          }
        }

        .g2 {
          background: linear-gradient(to right, #64dbad, #3566f6);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 53px;

          @media (max-width: 992px) {
            font-size: 40px;
          }

          @media (max-width: 400px) {
            font-size: 33px;
          }
        }

        @media (max-width: 992px) {
          font-size: 40px;
        }

        @media (max-width: 400px) {
          font-size: 33px;
        }
      }

      .sup-title {
        @include paragraph-large();
        margin-bottom: 60px;

        @media (max-width: 992px) {
          margin-bottom: 22px;
        }

        .better-min {
          @include better-min;
        }

        p {
          margin-bottom: 15px;
        }
      }

      .header-button {
        margin: 0;
      }

      .header-button-reverse {
        @include header-button-reverse("/images/all/Header-Video-icone.png");
      }
    }

    .left-side {
      text-align: left;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  // section-parts///////////////////////////////////////////////////

  .section-parts {
    @include fullscreen-box();
    position: relative;
    background-image: url("/images/all/Home-Section2-Background.png");
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-size: 20%;

    @media (max-width: 992px) {
      background-image: none;
    }

    .gradiant-background {
      width: 100%;
      padding-bottom: 170px;
      background: linear-gradient(180deg, #ffffff00 50%, #eef2ff 100%);

      @media (max-width: 992px) {
        padding-bottom: 0;
      }

      .flow-image {
        display: none;

        @media (max-width: 992px) {
          display: inline-block;
          margin-bottom: -85px;
        }
      }
    }

    .s2-text-holder {
      justify-content: space-between;
      align-items: center;

      @media (max-width: 992px) {
        padding-bottom: 60px;
      }

      h1 {
        @include title-regular();
        text-align: center;
        margin-bottom: 78px;

        @media (max-width: 992px) {
          margin-bottom: 24px;
        }
      }

      .sub-text {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .part {
          display: flex;
          flex-direction: column;
          max-width: 250px;

          @media (max-width: 992px) {
            max-width: 69%;
          }

          h2 {
            font-size: 80px;
            font-weight: bold;
            line-height: 118px;

            @media (max-width: 992px) {
              font-size: 58px;
              line-height: 85px;
            }
          }

          h3 {
            @include paragraph-large();
            font-weight: 700;
          }

          p {
            @include paragraph-small();
          }
        }
      }
    }
  }

  // section-save
  .section-save {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background: linear-gradient(103.48deg, #1b35db -8.94%, #3566f6 107.29%);

    .chat-background {
      width: 100%;
      background-image: url("/images/all/Companies-Section3-Background.png");
      background-repeat: no-repeat;
      background-position: center;
      background-position-x: 10%;
      background-size: contain;

      @media (max-width: 992px) {
        background-image: url("/images/all/Companies-Section3-Background-mobile.png");
        background-repeat: no-repeat;
        background-position-y: 81%;
      }
    }

    .space {
      display: flex;
      justify-content: space-around;
      padding-top: 90px;
      padding-bottom: 90px;

      @media (max-width: 992px) {
        padding-top: 40px;
        padding-bottom: 0px;
      }
    }

    .text-holder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 45%;

      @media (max-width: 992px) {
        width: 100%;
      }

      .title-3 {
        @include title-regular();
        display: inline;
        margin-bottom: 35px;
      }

      p {
        @include paragraph-small();
        margin-bottom: 46px;
      }
    }

    .left-side {
      overflow: hidden;
      margin-top: 55px;
      left: 0;

      img {
        max-width: 400px;
      }

      @media (max-width: 992px) {
        margin-top: 0px;

        img {
          max-width: 328px;
          margin-bottom: -50px;
        }

        padding-top: 0px;
      }
    }
  }

  // setion commit //////////////////////
  .section-commit {
    background: #eef2ff;
    padding: 60px 0;

    @media (max-width: 992px) {
      padding: 80px 0;
    }

    .row {
      justify-content: space-around;
    }

    .right-side {
      padding-top: 120px;

      @media (max-width: 992px) {
        padding-top: 0;
      }

      .title {
        @include title-regular();
        letter-spacing: 0em;
        margin-bottom: 60px;
        min-width: 710px;

        @media (max-width: 992px) {
          min-width: 0;
          margin-bottom: 34px;
        }

        .g1 {
          background: linear-gradient(89.85deg,
              #65dcae 14.55%,
              #3566f6 73.07%,
              #000000 155.11%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;

          @media (max-width: 400px) {
            font-size: 35px;
          }
        }
      }

      .sup-title {
        @include paragraph-regular();
        margin-bottom: 10px;
        padding-left: 95px;

        @media (max-width: 992px) {
          padding-left: 0;
          margin-bottom: 40px;
        }

        br.mobile-brake {
          display: none;

          @media (max-width: 992px) {
            display: block;
          }
        }
      }
    }

    .left-side {
      text-align: left;

      img.web {
        display: flex;

        @media (max-width: 992px) {}
      }
    }
  }

  .security-and-privacy {
    margin-top: 0;
    padding: 100px 15px;

    @media (max-width: 992px) {
      padding: 40px 15px;
    }

    .row {
      justify-content: space-between;
    }

    .right-side {
      @media (max-width: 992px) {
        display: none;
      }

      .company-logo {
        display: grid;
        grid-template-rows: repeat(2, 46%);
        grid-template-columns: repeat(2, 47%);
        text-align: right;
        grid-row-gap: 26px;
        grid-column-gap: 30px;
        margin-bottom: 65px;

        .company-logo-1,
        .company-logo-2,
        .company-logo-3,
        .company-logo-4 {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 25px 15px;
          border: 1px solid #3566f6;

          @media (max-width: 992px) {
            display: none;
          }
        }
      }

      .security-icon {
        display: flex;
        justify-content: space-between;

        @media (max-width: 992px) {
          display: none;
        }
      }
    }

    .left-side {
      @media (max-width: 992px) {
        padding: 60px 0;

        @media (max-width: 992px) {
          padding: 0;
        }
      }

      .title {
        @include title-regular();
        margin-bottom: 38px;

        @media (max-width: 992px) {
          margin-bottom: 40px;
        }
      }

      .company-logo-mobile {
        display: none;

        @media (max-width: 992px) {
          display: grid;
          grid-template-rows: repeat(2, 50%);
          grid-template-columns: repeat(2, 50%);
          text-align: right;
          grid-row-gap: 17px;
          grid-column-gap: 19px;

          margin-bottom: 30px;
        }

        .company-logo-mobile-1,
        .company-logo-mobile-2,
        .company-logo-mobile-3,
        .company-logo-mobile-4 {
          display: none;

          @media (max-width: 992px) {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 0;
            border: 1px solid #3566f6;
          }
        }
      }

      .security-icon-mobile {
        display: none;

        @media (max-width: 992px) {
          display: flex;
          justify-content: space-between;
          padding: 4px;
        }
      }

      .sup-title {
        @include paragraph-regular();
        margin-bottom: 30px;
      }
    }
  }

  //section6 save//////////////////////////////////
  .section6-save {
    color: #ffffff;
    background: linear-gradient(103.48deg, #1b35db -8.94%, #3566f6 107.29%);
    padding: 90px 0;

    @media (max-width: 992px) {
      padding: 40px 0;
    }

    .right-side {
      display: flex;
      flex-direction: column;
      padding-top: 30px;
      padding-left: 112px;
      color: white;

      @media (max-width: 992px) {
        padding: 0;
      }

      .title {
        @include title-regular();
        margin-bottom: 70px;

        @media (max-width: 992px) {
          margin-bottom: 30px;
        }
      }

      .bold-green {
        @include paragraph-small();
        font-weight: 800;
        margin-bottom: 34px;
      }

      .paragraph {
        @include paragraph-small();
      }
    }

    .left-side {
      text-align: left;

      @media (max-width: 992px) {
        margin-right: -35px;
      }

      .web-image {
        display: inline-block;

        @media (max-width: 992px) {
          display: none;
        }
      }

      .mobile-image {
        display: none;

        @media (max-width: 992px) {
          display: inline-block;
          margin-top: 40px;
        }
      }
    }
  }
}