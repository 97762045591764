.banner {
    background: #EEF2FF;
    font-family: "Heebo", Arial, sans-serif;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    width: 30%;
    height: 45%;

    @media (min-width: 600px) and (max-width: 900px) {
        width: 70%;
    }

    position: fixed;
    z-index: 1000;

    .row {
        margin-bottom: 40px;
    }

    .col-0 {
        width: 100%;
        padding: 0 15px;
    }

    .close-button {
        position: absolute;
        top: -20px;
        right: 0px;
        cursor: pointer;
        color: rgba(99, 217, 173, 1);
        font-size: 1.1em;

        @media (max-width: 767px) {
            top: -35px;
        }

        @media (min-width: 600px) and (max-width: 900px) {
            top: -25px;
        }
    }

    .headings {
        display: grid;
        grid-template-columns: 20% 1fr 15%;
        align-items: center;
        margin-bottom: 2%;
        cursor: pointer;

        .titles {
            font-size: clamp(0.3em, 0.88vw, 1.2vw);
            @media (min-width: 600px) and (max-width: 900px) {
                font-size: 12px;
            }
        }

        .top-right-img {
            align-self: center;
            justify-self: center;
        }

        .arrow-img {
            align-self: center;
            justify-self: center;
            position: relative;
            top: -9px;
            width: 27px;

            @media (max-width: 767px) {
                width: 17px;
                margin-left: 25%;
                margin-top: 25%;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .sub-heading {
            margin-top: -1%;
        }
    }

    .contact-form {
        display: grid;
        justify-content: center;
        font-size: 1.4vw;
        margin: 5%;

        @media (min-width: 600px) and (max-width: 900px) {
            font-size: 25px;
        }

        .form-fields {
            input {
                background-color: #EEF2FF;
                border: 0;
                border-bottom: 1px solid #3566f6;
                width: 100%;
                // padding: 0.5em;
                margin-bottom: 2%;
                font-size: 0.8em;

                @media (min-width: 600px) and (max-width: 900px) {
                    font-size: 25px;
                }

                &:focus {
                    outline: none;
                }
            }
            @media (min-width: 600px) and (max-width: 900px) {
                .col-0,
                .col-4,
                .col-8 {
                    margin-top: 0px;
                }
                .col-0 {
                    margin-bottom: -15px;
                }
                .col-8 {
                    margin-bottom: 0px;
                }
            }
        }

        .button-wrapper {
            margin-bottom: 2%;

            img {
                margin-left: 2%;
                margin-bottom: -1%;
            }

            #input-submit {
                width: 100%;
                background: rgba(48, 93, 241, 1);
                color: white;
                border: none;
                font-size: 1.2vw;
                border-radius: 8px;
                margin-top: 3.5%;
                padding: 2% 0;
                cursor: pointer;

                &:hover {
                    background: rgb(37, 70, 177);
                }

                &:disabled {
                    background: rgba(48, 93, 241, 1);
                    cursor: default;
                }
                
                @media (min-width: 600px) and (max-width: 900px) {
                    font-size: 25px;
                }
            }
        }
    }
}

.banner.close {
    height: 10%;
    bottom: 0%;
    left: 0%;
    box-shadow: -10px -10px 15px rgba(0, 0, 0, 0.1);
}

.banner.open {
    bottom: 0%;
    left: 0%;
    box-shadow: -10px -10px 15px rgba(0, 0, 0, 0.1);
}

/* Media query for mobile screens */
@media (max-width: 767px) {
    .banner {
        width: 70%;
        /* Mobile width (wider banner) */
        height: auto;
        font-size: 30px;

        .arrow-img {
            width: 50%;
        }

        .top-right-img {
            width: 80%;
        }

        .contact-form {
            font-size: 19px;

            .form-fields {
                .row {
                    display: initial;
                }

                .col-0,
                .col-4,
                .col-8 {
                    margin-top: 15px;
                }

                .col-8 {
                    margin-bottom: 15px;
                }
            }

            .button-wrapper {
                #input-submit {
                    font-size: 17px;
                }
            }
        }

    }

    .banner.close {
        // height: 12%;
    }
}