@import "../../variables";
.container.row {
  justify-content: space-between;
  position: relative;
}
.contact {
  @include gradient-top();
  padding-top: 168px;
  padding-bottom: 160px;
  @media (max-width: 480px) {
    padding-top: 130px;
    padding-bottom: 60px;
  }
  .contact-form {
    display: flex;
    flex-direction: row;
    .mobile-break {
      display: none;
      @media (max-width: 992px) {
        display: block;
      }
    }
    .right-side {
      width: 89%;
      @media (max-width: 992px) {
        width: 100%;
      }
      .title {
        @include title-regular();
        margin-bottom: 24px;
        @media (max-width: 992px) {
          font-size: 43px;
          line-height: 48px;
        }
        @media (max-width: 400px) {
          font-size: 40px;
          line-height: 48px;
        }
      }
      .sup-title {
        @include paragraph-small();
        a {
          cursor: pointer;
          color: #2c55eb;
          font-weight: 800;
          text-decoration: underline;
        }
      }
      form.cf {
        margin-right: 0px;
        max-width: 550px;
        @media (max-width: 480px) {
          padding-left: 10px;
        }
      }
      img.web {
        @media (max-width: 480px) {
          display: none;
        }
      }
      .left-side {
        display: flex;
        justify-content: center;
        align-items: end;
      }
      form {
        text-align: right;
        margin: 70px auto;
        @media (max-width: 992px) {
          margin: 30px auto;
        }
        h6 {
          padding-top: 20px;
          font-size: 18px;
        }
        .col-4,
        .col-8 {
          padding: 0;
        }
        .col-4 {
          padding-left: 18px;
          @media (max-width: 992px) {
            padding-left: 0;
          }
        }
        input,
        textarea {
          font-size: 18px;
          border: 0;
          border-bottom: 1px solid #3566f6;
          outline: 0;
          padding: 1em;
          display: block;
          width: 100%;
          padding-right: 0;
          padding-bottom: 6px;
          resize: none;
          &:focus {
            border-bottom: 1px solid #1b35db;
          }
          @media (max-width: 992px) {
            font-size: 18px;
          }
        }

        .button-wrapper {
          margin-top: 45px;
          h4 {
            color: #db3333;
            text-align: center;
          }
        }

        #input-submit {
          @include paragraph-small();
          font-weight: 500;
          color: white;
          background: linear-gradient(
            103.48deg,
            #1b35db -8.94%,
            #3566f6 107.29%
          );
          -webkit-appearance: none;

          border-radius: 8px;
          margin-top: 15px;
          padding: 12px 0;
          cursor: pointer;
          &:hover {
            background: #042abb;
          }
          &:focus {
            background: #042abb;
          }
        }
      }

      /* Clearfix */
      .cf:before,
      .cf:after {
        content: " "; /* 1 */
        display: table; /* 2 */
      }

      .cf:after {
        clear: both;
      }
      .recevied-massage {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgb(102, 223, 172, 0.1);
        padding: 100px 160px;
        border-radius: 6px;
        margin-top: 60px;
        max-width: 75%;
        @media (max-width: 992px) {
          margin-bottom: 14px;
          font-size: 24px;
          line-height: 35px;
          text-align: center;
          padding: 53px;
          max-width: none;
        }

        img {
          margin-bottom: 30px;
          max-width: 60px;
        }
        h6 {
          margin-bottom: 20px;
          font-size: 32px;
          font-weight: 700;
          line-height: 47px;
          text-align: center;
        }
      }
    }
    .left-side {
      width: 35%;
      @media (max-width: 992px) {
        display: none;
      }
      img.web {
        max-width: 622px;
        width: auto;
        padding-top: 20vh;
        margin-right: -100px;
      }
    }
  }
}
