@mixin paragraph-large() {
  font-size: 24px;
  line-height: 35px;
  font-weight: 400;
  @media (max-width: 992px) {
    font-size: 18px;
    line-height: 30px;
  }
  @media (max-width: 400px) {
    font-size: 16px;
  }
}

@mixin paragraph-regular() {
  font-size: 22px;
  line-height: 35px;
  font-weight: 400;
  @media (max-width: 992px) {
    font-size: 18px;
  }
}

@mixin paragraph-small() {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  @media (max-width: 992px) {
    font-size: 18px;
  }
}

@mixin title-large() {
  font-size: 55px;
  font-weight: bold;
  line-height: 70px;
  letter-spacing: -0.02em;
  @media (max-width: 992px) {
    font-size: 46px;
    font-weight: bold;
    line-height: 60px;
    letter-spacing: -0.02em;
  }
  @media (max-width: 400px) {
    font-size: 40px;
    font-weight: bold;
    line-height: 50px;
    letter-spacing: -0.02em;
  }
}
@mixin title-regular() {
  font-size: 50px;
  font-weight: bold;
  line-height: 60px;
  letter-spacing: -0.02em;
  @media (max-width: 992px) {
    font-size: 40px;
    font-weight: 800;
    line-height: 41px;
    letter-spacing: 0;
  }
  @media (max-width: 400px) {
    font-size: 33px;
  }
}
@mixin title-small() {
}
@mixin font-size($size) {
  font-size: $size;
  line-height: $size * 1.3;
  @media (max-width: 992px) {
    font-size: $size * 0.8;
    line-height: $size * 1.1;
  }
}

@mixin fullscreen-box() {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin header-button($url: "") {
  background: linear-gradient(103.48deg, #1b35db -8.94%, #3566f6 107.29%);
  margin: auto;
  font-size: 17px;
  position: relative;
  border-radius: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 24px 16px 24px;
  color: white;
  text-decoration: none;

  &::after {
    background-image: url(#{$url});
    position: static;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 16px;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    height: 16px;
    width: 29.484359741210938px;
    left: 24px;
    top: 20px;
    border-radius: 0px;

    content: "";
  }
  &:focus {
    background: #042abb;
  }
  &:hover {
    background: #042abb;
  }
}
@mixin zoom-button-gray($gray: "") {
  background: #eef2ff;
  font-size: 22px;
  font-weight: 700;
  border-radius: 8px;
  padding: 24px 40px 24px 40px;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  text-decoration: none;
  max-width: fit-content;
  &::after {
    background-image: url(#{$gray});
    position: static;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 16px;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    height: 16px;
    width: 29.484359741210938px;
    left: 24px;
    top: 20px;
    border-radius: 0px;

    content: "";
  }
  &:focus {
    background: #042abb;
  }
  &:hover {
    background: #042abb;
    color:white;
  }
  @media (max-width: 400px) {
    font-size: 17px;
  }
}

@mixin header-button-reverse($re: "") {
  background: radial-gradient(
      28.19% 127.57% at 21.75% 123.13%,
      #63d9ad 0%,
      #2d53e4 100%
    ),
    #2d53e4;

  font-size: 22px;
  font-weight: 700;
  border-radius: 8px;
  padding: 24px 40px 24px 40px;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
  max-width: fit-content;
  &::after {
    background-image: url(#{$re});
    position: static;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 16px;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    height: 16px;
    width: 29.484359741210938px;
    left: 24px;
    top: 20px;
    border-radius: 0px;

    content: "";
  }
  &:focus {
    background: #042abb;
  }
  &:hover {
    background: #042abb;
  }
  @media (max-width: 400px) {
    font-size: 17px;
  }
}
@mixin better-min {
  margin-left: 10px;
  width: 90;
  height: 18px;
  @media (max-width: 992px) {
    width: 74.67px;
    height: 15px;
  }
}
@mixin fullscreen-mobile-in-container {
  //35px = 20px(container) + 15px(row)
  margin-right: -35px;
  margin-left: -35px;
  width: calc(100% + 70px);
  max-width: none;
}
@mixin gradient-top {
  width: 100%;
  background: linear-gradient(360deg, #ffffff00 50%, #eef2ff 100%);
}
