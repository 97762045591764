$mobile-width: "(max-width: 992px)";
$xs-mobile-width: "(max-width: 355px)";
$sm-mobile-width: "(min-width: 355px) and (max-width: 365px)";
$md-mobile-width: "(min-width: 365px) and (max-width: 375px)";
$lg-mobile-width: "(min-width: 375px) and (max-width: 399px)";
$xl-mobile-width: "(min-width: 400px) and (max-width: 420px)";
$xxl-mobile-width: "(min-width: 420px) and (max-width: 450px)";
$sm-tablet-width: "(min-width: 600px) and (max-width: 780px)";
$md-tablet-width: "(min-width: 780px) and (max-width: 992px)";
$sm-laptop-width: "(min-width: 992px) and (max-width: 1400px)";
$md-laptop-width: "(min-width: 1400px) and (max-width: 1700px)";
$lg-mobile-height: "(min-height: 820px) and (max-height: 870px)";
$xl-mobile-height: "(min-height: 870px)";
$destop-width: "(min-width: 992px)";


.lp-container {
    max-width: 1700px;
    padding-left: 60px;
    padding-right: 60px;
    margin-left: auto;
    margin-right: auto;

    @media #{$mobile-width} {
        padding-left: 15px;
        padding-right: 15px;
        max-width: 900px;
        margin-left: none;
        margin-right: none;
    }
}

.blue-button {
    font-family: "Heebo", Arial, sans-serif !important;
    background: #1a39da;
    // font-size: 1.3vw; 
    font-size: 25px;
    border-radius: 40px;
    color: white;
    border: none;
    font-weight: 400;
    cursor: pointer;
    width: 321px;
    height: 64px;

    &:focus {
        background: #042abb;
    }

    &:hover {
        background: #042abb;
    }

    @media #{$mobile-width} {
        width: 100%;
        font-size: 20px;
    }
}

.marked-text {
    font-weight: bold;
    background-color: #5AE3CF;
    border-radius: 15px 8px 15px 10px;
}

.gradient-text {
    background: linear-gradient(270deg, #1A39DA 0%, #5AE3CF 25%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.vertical-line {
    height: 40%;
    width: 1px;
    background-color: #ccc;
}

.horizontal-line {
    width: 100%;
    height: 1px;
    background-color: #ccc;
}

.landing-page {
    overflow-x: hidden !important;
    font-family: "Heebo",
        Arial,
        sans-serif !important;
    -webkit-tap-highlight-color: transparent;

    .header {
        background-color: rgba(233, 237, 255, 1);
        padding: 15px 15px 15px 15px;
        z-index: 999;
        overflow: hidden;
        min-height: 10vh;



        @media #{$mobile-width} {
            height: 80px;
        }

        .header-body {
            height: 9vh;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media #{$mobile-width} {
                height: 55px;
            }

            @media #{$mobile-width} and #{$xl-mobile-height} {
                height: 75px;
            }

            img {
                height: 200px;
                width: 200px;

                @media #{$mobile-width} {
                    width: 120px;
                    height: 120px;
                    margin-right: 20px;
                }
            }

            .header-nav {
                @media #{$mobile-width} {
                    display: none;
                }

                ul {
                    list-style: none;
                    display: flex;
                    gap: 35px;
                    font-size: 1.2vw;

                    a {
                        color: #070C4E;
                        text-decoration: none;
                        transition: color 0.3s ease;
                        cursor: pointer;
                    }
                }
            }

            .mobile-buttons-left {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;
            }

            .header-zoom {
                @media #{$mobile-width} {
                    margin-right: 40px;
                }

                @media #{$xs-mobile-width} {
                    margin-right: 20px;
                }

                @media #{$sm-tablet-width} {
                    margin-right: 380px;
                }

                .mobile-button {
                    img {
                        display: none;

                        @media #{$mobile-width} {
                            display: block;
                            width: 46px;
                            height: 46px;

                            &:focus {
                                content: url("/images/all/mobile-Video-icone-click.png");
                            }

                            &:hover {
                                content: url("/images/all/mobile-Video-icone-click.png");
                            }
                        }
                    }
                }

                .desktop-button {
                    @media #{$mobile-width} {
                        display: none;
                    }
                }
            }

            .header-menu {

                @media #{$destop-width} {
                    display: none;
                }

                .nav-menu-non-active {
                    display: none;
                }

                .nav-menu-active {
                    li {
                        list-style-type: none;
                    }

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    background-color: rgb(250, 251, 255);
                    position: absolute;
                    top: 80px;
                    height: calc(100% - 80px);
                    left: 0;
                    width: 100%;
                    z-index: 999;
                    gap: 60px;
                    font-size: 1.5vw;

                    a {
                        color: #070C4E;
                        text-decoration: none;
                        transition: color 0.3s ease;
                        cursor: pointer;
                    }
                }
            }

        }
    }


    .section1 {

        @media #{$mobile-width} {
            margin-bottom: 40px;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            margin-top: 4vh;

            .section1-button {
                width: 90%;
                font-size: 16px;
            }
        }

        @media #{$sm-tablet-width} {
            text-align: center;
            margin-top: 5vh;
        }

        margin-top: 10vh;

        @media #{$md-laptop-width} {
            margin-top: 5vh;
        }

        display: flex;
        justify-content: space-between;

        .section1-text {
            // border: 1px solid red;
            width: 70%;
            font-size: 3.6vw;
            font-weight: bold;
            color: rgba(7, 12, 78, 1);

            p {
                font-size: 50%;
                font-weight: 400;
                margin-top: 15px;
                margin-bottom: 15px;
            }

            span {
                font-size: 140%;
                color: rgba(26, 57, 218, 1);
                font-weight: bold;
            }

            @media #{$mobile-width} {
                font-size: 32px;
                width: 100%;

                p {
                    // border: 1px solid red;
                    font-size: 18px;
                    margin-top: 5px;
                    margin-bottom: 0px;

                    // padding-left: 60px;
                    .section1-text-amount {
                        font-size: 120%;
                    }
                }
            }
        }


        .section1-gif {
            margin-right: 150px;

            video::-webkit-media-controls {
                display: none !important;
            }

            video::-webkit-media-controls-enclosure {
                display: none !important;
            }

            video::-webkit-media-controls-panel {
                display: none !important;
            }

            video {
                border: 0;
                outline: 0;
                width: 100%;
                height: 100%;
            }

            @media #{$mobile-width} {
                margin-right: 0;
            }
        }
    }


    .section2 {
        justify-content: space-between;
        padding: calc((100vw - 1700px) / 2);
        display: flex;
        justify-content: center;

        @media #{$md-laptop-width} {
            padding-top: 100px;
            padding-bottom: 100px;
        }

        @for $i from 1 through 10 {
            .row#{$i} {
                display: flex;
                gap: 50px;
                margin-top: 50px;

                img {
                    width: 10vw;
                    height: 10vw;
                }
            }
        }

        @media #{$mobile-width} {
            padding: 0;
            padding-top: 30px;
            padding-bottom: 30px;

            @for $i from 1 through 10 {
                .row#{$i} {
                    display: flex;
                    gap: 20px;
                    margin-top: 30px;

                    img {
                        width: 25vw;
                        height: 25vw;
                    }
                }
            }
        }
    }



    .section3 {
        min-height: 70vh;
        background: linear-gradient(100deg, #5AE3CF, #1A39DA);
        display: flex;
        justify-content: center;
        align-items: center;

        @media #{$mobile-width} {
            text-align: center;
            min-height: 30vh;
        }

        .section3-body {
            .section3-text {
                text-align: center;
                color: white;
                font-size: 70px;
                // font-size: 5.5vw;
                font-weight: 500;

                @media #{$mobile-width} {
                    font-size: 30px;
                }

                @media #{$sm-tablet-width} {
                    font-size: 40px;
                }
            }

            .section3-amount {
                margin-right: 40px;
                display: flex;
                justify-content: center;

                @media #{$mobile-width} {
                    align-items: center;
                    margin-right: 10px;
                }

                .white-box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 60px;
                    height: 90px;
                    background: linear-gradient(180deg, #FFFFFF 0%, #EFEEEE 20.31%, #F8F8F8 99.48%);
                    color: rgba(7, 12, 78, 1);
                    padding: 0px, 8px, 0px, 8px;
                    margin: 5px;
                    text-align: center;
                    line-height: 40px;
                    font-size: 75px;
                    font-weight: bold;
                    border-radius: 3px;
                    padding-top: 5px;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

                    @media #{$mobile-width} {
                        width: 30px;
                        height: 30px;
                        font-size: 20px;
                        line-height: 30px;
                        margin: 2px;
                    }

                    @media #{$sm-tablet-width} {
                        width: 60px;
                        height: 70px;
                        font-size: 50px;
                    }

                }

                .comma {
                    display: inline;
                    color: white;
                    font-size: 50px;
                    margin-top: 35px;
                    font-weight: bold;

                    @media #{$mobile-width} {
                        font-size: 15px;
                        margin-top: 15px;
                        margin-left: 2px;
                        margin-right: 2px;
                    }

                    @media #{$sm-tablet-width} {
                        font-size: 40px;
                        margin-top: 25px;
                    }
                }

                .sheqel-sign {
                    display: inline;
                    color: white;
                    font-size: 50px;
                    align-self: center;
                    font-weight: 400;

                    @media #{$mobile-width} {
                        font-size: 18px;
                        margin-top: 5px;
                        margin-right: 2px;
                    }

                    @media #{$sm-tablet-width} {
                        font-size: 40px;
                    }
                }

            }
        }
    }

    .section4 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        padding-top: 15vh;


        @media #{$mobile-width} {
            padding: 0;
            min-height: 50vh;
            justify-content: flex-start;

            @media #{$mobile-width} and (min-height: 750px) {
                min-height: 40vh;
            }
        }

        .section4-text {
            font-size: 70px;
            margin-bottom: 5vh;
            font-weight: 400;
            line-height: 90px;
            letter-spacing: -0.02em;

            @media #{$mobile-width} {
                font-size: 24px;
                font-weight: 400;
                line-height: 34px;
                letter-spacing: -0.48px;
                margin-top: 30px;
                margin-bottom: 30px;
                // margin: 0;
                padding: 0;
                // letter-spacing: 0;
                // border: 1px solid red;

            }

            @media #{$sm-tablet-width} {
                font-size: 30px;
            }

            .gradient-text-section4 {
                font-weight: 800;
                background: linear-gradient(270deg, #1A39DA 30%, #5AE3CF 95%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .section4-video {
            cursor: pointer;
            width: 100vw;
            margin-bottom: 90px;

            @media #{$mobile-width} {
                margin-bottom: 10px;
            }
        }
    }

    .section5 {
        overflow: hidden;
        position: relative;
        min-height: 250vh;

        @media #{$md-laptop-width} {
            min-height: 260vh;
        }

        @media #{$mobile-width} and #{$lg-mobile-height} {
            min-height: 220vh;
        }

        @media #{$mobile-width} and #{$xl-mobile-height} {
            min-height: 205vh;
        }

        @media #{$sm-tablet-width} {
            min-height: 240vh;
        }

        color: rgba(7, 12, 78, 1);
        display: flex;
        flex-direction: column;

        @media #{$mobile-width} {
            justify-content: space-between;
        }

        &-text {
            margin-right: 150px;
            text-align: right;
            width: 60%;
            margin-bottom: 12vh;

            @media #{$md-laptop-width} {
                margin-right: 80px;
            }

            @media #{$mobile-width} {
                width: 100%;
                margin-bottom: 1vh;
                margin-right: 0;
                padding: 19px;
            }

            &-title {
                font-size: 70px;
                // font-size: 6vw;
                font-weight: 600;
                margin-bottom: 20px;

                @media #{$mobile-width} {
                    font-size: 30px;
                    text-align: center;
                }

                @media #{$sm-tablet-width} {
                    font-size: 40px;
                }
            }

            p {
                font-size: 34px;
                // font-size: 2vw;
                letter-spacing: 1px;
                font-weight: 400;

                @media #{$mobile-width} {
                    font-size: 18px;
                    line-height: 26px;
                }

                @media #{$sm-tablet-width} {
                    font-size: 25px;
                }
            }
        }

        &-body {
            display: flex;
            gap: 5vw;

            @media #{$mobile-width} {
                flex-direction: column;
                gap: 15px;
            }

            &-right {
                width: 60%;

                @media #{$mobile-width} {
                    width: 95%;
                }

                &-number {
                    width: 150px;
                    height: 150px;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 70px;
                    color: white;
                    margin-bottom: 30px;
                    margin-top: 10vh;

                    @media #{$mobile-width} {
                        width: 70px;
                        height: 70px;
                        font-size: 30px;
                        margin-bottom: 10px;
                        margin-top: 20px;
                    }

                }

                &-text {
                    display: flex;
                    gap: 100px;

                    @media #{$mobile-width} {
                        gap: 10px;
                        // border: 1px solid red;
                        margin-bottom: 40px;
                    }

                    &-right {
                        // border: 1px solid red;
                        font-weight: bold;
                        font-size: 40px;
                        // width: 300px;
                        width: 60%;
                        // margin-right: 15px;

                        @media #{$mobile-width} {
                            font-size: 24px;
                            width: 50%;
                            margin-right: 0;
                            // text-align: center;
                        }

                        @media (min-width: 360px) and (max-width: 410px) {
                            width: 60%;
                            // border: 1px solid red;

                        }

                        @media #{$sm-tablet-width} {
                            font-size: 25px;
                        }

                        // border: 1px solid red;

                    }

                    &-left {
                        @media #{$mobile-width} {
                            font-size: 18px;
                            width: 90%;
                            line-height: 26px;
                            // border: 1px solid red;
                        }

                        @media #{$sm-tablet-width} {
                            font-size: 25px;
                        }

                        @media (min-width: 1400px) {
                            // margin-right: 60px;
                        }

                        width: 180%;
                        font-size: 28px;
                        font-weight: 400;
                        // border: 1px solid red;
                    }
                }
            }

            &-left {
                display: flex;
                flex-direction: column;
                gap: 10vh;

                &-zoom {
                    @media #{$mobile-width} {
                        display: none;
                    }
                }

                &-phone {
                    @media #{$mobile-width} {
                        margin-top: 40px;
                    }
                }
            }
        }

        &-circle {
            background: rgba(233, 237, 255, 1);
            border-radius: 100%;
            position: absolute;
            width: 934px;
            height: 934px;
            z-index: -1;
            top: 70vh;
            right: 40vw;

            @media #{$mobile-width} {
                width: 300px;
                height: 300px;
                top: 142vh;
                right: 140px;

                @media #{$mobile-width} and #{$lg-mobile-height} {
                    top: 100vh;
                }
            }

            @media #{$sm-tablet-width} {
                width: 700px;
                height: 700px;
                top: 80vh;
                right: 250px;
            }
        }


        &-wave {
            width: 100vw;
            position: absolute;
            z-index: -1;
            left: 0px;
            bottom: -30px;

            img {
                cover: fit;
                object-fit: cover;
            }
        }

        &-wave-mobile {
            background: rgba(26, 57, 218, 1);
            border-radius: 100%;
            position: absolute;
            width: 800px;
            height: 800px;
            z-index: -1;
            bottom: -350px;
            left: -300px;

            @media #{$sm-tablet-width} {
                width: 900px;
                height: 900px;
                bottom: -350px;
                left: -100px;
            }

            @media #{$md-tablet-width} {
                width: 1000px;
                height: 1000px;
                bottom: -350px;
                left: -100px;
            }
        }

        &-lower-text {
            padding-top: 22vh;

            // border: 1px solid red;
            &-safari {
                // position: absolute;
                // bottom: 15px;
            }

            @media #{$mobile-width} and (min-height: 700px) {
                padding-top: 6vh;
                // border: 1px solid red;
            }

            @media #{$mobile-width} and (min-height: 900px) {
                padding-top: 8vh;
                // border: 1px solid red;
            }

            @media #{$mobile-width} and (min-height: 600px) {
                padding-top: 5vh;
                // border: 1px solid red;
            }

            align-self: center;
            font-size: 70px;
            font-weight: 600;
            color: white;

            span {
                color: rgba(90, 227, 207, 1)
            }

            @media #{$mobile-width} {
                font-size: 30px;
                text-align: center;
                // justify-self: flex-end;
            }

            @media #{$sm-tablet-width} {
                font-size: 50px;
            }
        }
    }


    .section6 {

        min-height: 70vh;
        background-color: rgba(26, 57, 218, 1);
        text-align: center;
        color: white;
        padding: 20px;
        font-weight: 100;
        margin-top: -2px;
        padding-bottom: 50px;

        &-safari {
            @media #{$mobile-width} and (min-height: 700px) {
                padding-top: 0px;
                margin-top: -10px;
            }
        }

        @media #{$mobile-width} {
            padding-top: 5px;
            padding-right: 36px;
            padding-left: 36px;
            font-weight: 400;
            min-height: 50vh;
        }

        @media (min-height: 850px) and (max-height: 930px) {
            padding-top: 0px;
            // border: 1px solid red;;

        }

        @media (min-height: 930px) and (max-height: 970px) {
            padding-top: 0px;
            margin-top: -20px;
            // border: 1px solid red;;
        }

        &-text {
            font-size: 34px;

            @media #{$mobile-width} {
                font-size: 18px;
                line-height: 26px;
            }

            @media #{$sm-tablet-width} {
                font-size: 25px;
                padding-left: 10px;
                padding-right: 10px;
            }

            &-special {
                color: rgba(90, 227, 207, 1);
                font-size: 34px;
                font-weight: 500;

                @media #{$mobile-width} {
                    font-size: 18px;
                }

                @media #{$sm-tablet-width} {
                    font-size: 25px;
                }
            }
        }

        &-logo {
            margin-top: 5vh;
            margin-bottom: 5vh;

            @media #{$mobile-width} {
                margin: 0;
                margin-top: 10px;

                img {
                    width: 50%;
                }
            }

            @media #{$sm-tablet-width} {
                img {
                    width: 30%;
                }
            }
        }

        &-bottom {
            margin-top: 100px;
            display: flex;
            justify-content: center;

            @media #{$mobile-width} {
                margin-top: 50px;
            }

            &-button {
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: "Heebo", Arial, sans-serif !important;
                background: white;
                font-size: 24px;
                border-radius: 40px;
                color: #1a39da;
                border: none;
                font-weight: 500;
                cursor: pointer;
                width: 321px;
                height: 64px;

                &:focus {
                    background: #042abb;
                    color: white
                }

                &:hover {
                    background: #042abb;
                    color: white
                }


                @media #{$mobile-width} {
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }
    }


    .section7 {
        min-height: 125vh;
        background-color: rgba(249, 249, 249, 1);
        color: rgba(7, 12, 78, 1);
        padding-top: 23vh;
        padding-bottom: 15vh;

        @media #{$mobile-width} {
            padding-top: 100px;
        }

        &-title {
            font-size: 55px;
            font-weight: 600;
            margin-bottom: 10vh;

            @media #{$mobile-width} {
                font-size: 28px;
                margin-bottom: 5vh;
                text-align: center;
            }

        }

        &-body {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 8vw;

            @media #{$mobile-width} {
                flex-direction: column-reverse;
                gap: 50px;
            }

            &-grid {
                margin-right: 1vw;
                display: flex;
                flex-direction: column;
                gap: 2vw;

                @media #{$mobile-width} {
                    gap: 60px;
                }

                &-row {
                    display: inline-flex;
                    justify-content: start;
                    align-items: center;
                    gap: 3vw;

                    @media #{$mobile-width} {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                    }
                }

                &-right {
                    width: 10vw;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media #{$mobile-width} {
                        width: 90%;
                    }
                }

                &-left {
                    font-size: 28px;

                    @media #{$mobile-width} {
                        padding-left: 40px;
                        padding-right: 40px;
                        font-size: 16px;
                        font-weight: 400;
                        text-align: center;
                    }
                }
            }

            &-lock {
                @media #{$mobile-width} {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 40%;
                    }
                }
            }
        }

        &-button {
            margin-top: 10vh;

            @media #{$mobile-width} {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            display: flex;
            justify-content: center;
            align-items: center;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: "Heebo", Arial, sans-serif !important;
                background: #1a39da;
                font-size: 24px;
                border-radius: 40px;
                color: white;
                border: none;
                font-weight: 500;
                cursor: pointer;
                width: 321px;
                height: 64px;

                &:focus {
                    background: #042abb;
                    color: white
                }

                &:hover {
                    background: #042abb;
                    color: white
                }


                @media #{$mobile-width} {
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }
    }

    .section8 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15vh;
        padding: 5%;
        min-height: 80vh;
        background: linear-gradient(360deg, #1A39DA -0%, #7DFCE9 100%);
        min-height: 150vh;

        @media #{$mobile-width} {
            min-height: 120vh;
            gap: 8vh;
            padding-bottom: 10vh;
        }

        .faq-container {
            display: flex;

            @media #{$mobile-width} {
                flex-direction: column;
                text-align: center;
                // padding: 10px;
                padding-top: 55px;
                width: 105%;
            }
        }

        &-title {
            color: #070C4E;
            font-weight: 800;
            font-size: 70px;
            width: 50%;
            margin-right: 30px;

            @media #{$mobile-width} {
                font-size: 30px;
                width: 100%;
                margin-right: 0px;
                margin-bottom: 40px;
            }
        }

        &-faq {
            width: 90%;
            margin-left: 10%;

            @media #{$mobile-width} {
                width: 100%;
            }

            @media #{$md-laptop-width} {
                margin-left: 5%;
            }
        }

        &-button-container {
            &-button {
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: "Heebo", Arial, sans-serif !important;
                background: white;
                font-size: 24px;
                border-radius: 40px;
                color: #1a39da;
                border: none;
                font-weight: 500;
                cursor: pointer;
                width: 321px;
                height: 64px;

                &:focus {
                    background: #042abb;
                    color: white
                }

                &:hover {
                    background: #042abb;
                    color: white
                }


                @media #{$mobile-width} {
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }
    }


    .section9 {
        min-height: 100vh;
        position: relative;
        padding: 0 15%;

        @media #{$mobile-width} {
            text-align: center;
            margin: 10px;
            margin-bottom: 90px;
            padding: 0;
        }

        @media #{$sm-tablet-width} {
            padding: 0 10%;
        }

        &-title {
            text-align: center;
            font-size: 70px;
            font-weight: 600;
            margin-bottom: 10vh;
            margin-top: 12vh;

            span {
                background: linear-gradient(270deg, #1A39DA 30%, #5AE3CF 85%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 600;
            }

            @media #{$mobile-width} {
                font-size: 30px;
                margin-bottom: 20px;
                font-weight: normal;

                span {
                    font-weight: 800;
                }
            }
        }

        &-body {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 4vw;

            @media #{$mobile-width} {
                flex-direction: column-reverse;
            }

            &-right {
                margin-right: 5vw;

                @media #{$md-laptop-width} {
                    margin-right: 0;
                }

                @media #{$mobile-width} {
                    margin-right: 0;
                    width: 100%;
                }

            }

            &-left {
                p {
                    margin-bottom: 8vh;
                    font-size: 34px;
                    font-weight: 300;

                    @media #{$mobile-width} {
                        color: var(--Dark-Blue, #070C4E);
                        text-align: center;
                        font-family: Heebo;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 36px;
                        margin-bottom: 4vh;
                    }
                }
            }
        }

        &-circle {
            position: absolute;
            width: 650px;
            height: 650px;
            z-index: -100;
            top: 15vh;
            left: 35vw;
            background: rgba(233, 237, 255, 1);
            border-radius: 100%;

            @media #{$md-laptop-width} {
                width: 550px;
                height: 550px;
                top: 20vh;
                left: 30vw;
            }

            @media #{$mobile-width} {
                width: 350px;
                height: 350px;
                top: 15vh;
                left: 50vw;
            }
        }
    }

    .footer {
        height: 30vh;
        background-color: rgba(26, 57, 218, 1);
        color: white;
        display: flex;

        @media #{$mobile-width} {
            height: 100%;
            min-height: 100vh;
            line-height: 25px;
        }

        @media #{$mobile-width} and #{$xl-mobile-height} {
            min-height: 80vh;
        }

        @media #{$mobile-width} and #{$lg-mobile-height} {
            min-height: 70vh;
        }


        &-body {
            width: 100%;

            @media (min-width: 1600px) {
                width: 80%;
            }

            display: flex;
            justify-content: space-between;
            align-items: center;

            @media #{$mobile-width} {
                flex-direction: column;
                gap: 60px;
                margin-top: 90px;
                margin-bottom: 60px;
                margin-left: 40px;
                margin-right: 40px;
            }

            &-better {
                p {
                    margin-top: 10px;
                    // margin-bottom: -5px;
                    font-size: 16px;
                    font-weight: bold;
                }
            }

            &-license {
                @media #{$mobile-width} {
                    width: 100%;
                }

                span {
                    font-size: 22px;
                    font-weight: 600;

                    @media #{$mobile-width} {
                        font-size: 20px;
                    }
                }

                ul {
                    list-style: none;

                    li {
                        font-size: 16px;
                        margin-top: 3px;
                        cursor: pointer;
                    }

                    @media #{$mobile-width} {
                        margin-top: 5px;

                        li {
                            font-size: 14px;
                            margin-bottom: 5px;
                        }
                    }
                }

                @media #{$mobile-width} {
                    order: 3;
                }
            }

            &-better {
                text-align: center;
                font-size: 22px;

                @media #{$mobile-width} {
                    order: 1;
                    margin-bottom: -5px;

                    img {
                        width: 60%;
                        height: 60%;
                    }
                }
            }

            &-sm {
                @media #{$mobile-width} {
                    order: 5;
                }

                display: flex;
                flex-direction: column;

                &-icons {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 30px;
                    margin-bottom: 10px;
                }

                &-text {
                    font-size: 16px;
                    text-align: center;

                    @media #{$mobile-width} {
                        font-size: 12px;
                    }

                    a {
                        color: white;
                        text-decoration: none;
                        transition: color 0.3s ease;
                        cursor: pointer;
                    }
                }
            }
        }
    }

}

.section1,
.section2,
.section3,
.section4,
.section5,
.section6,
.section7,
.section8,
.footer {
    @media #{$mobile-width} {
        overflow-x: hidden !important;
    }
}