@import "../../variables";

.home-container {
  width: 100%;
  margin-top: 88px;

  .carousel {
    margin-top: 50px;
    overflow: hidden;

    .carousel-title {
      text-align: center;
      margin-bottom: 20px;
      font-weight: bold;
      font-size: 130%;
    }

    @media (min-width: 1600px) {
      margin-top: 120px;
    }
  }

  @media (max-width: 992px) {
    margin-top: 78px;
  }

  .secondContainer {
    overflow: hidden;
  }

  .section-presentation {
    padding-bottom: 100px;
    //background-image: url("/images/all/Home-Section1-Background.png");
    //background-repeat: no-repeat;
    //background-position: left top;
    //background-position-x: 2em;
    //background-size: 50% auto;
    //background-size: calc(50% - 2em) auto;
    background-image: white;
    // background-image: linear-gradient(#eef2ff, #ffffff);
    position: relative;
    box-sizing: border-box;

    .gif-video {
      position: absolute;
      top: 3em;
      left: 13em;
      width: 30%;
      height: auto;
      z-index: 1;

      @media (max-width: 1600px) {
        top: 2em;
        left: 7em;
        width: 35%;
      }

      @media (max-width: 992px) {
        display: none;
      }

      border: none;
      border-width: 0;
      border-style: none;
      border-color: transparent;
      box-sizing: content-box;
      outline: none;
      border: none !important;

      @media all and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
        border: none;
      }

      border: none;
      -webkit-border: none;
      -moz-border: none;
      -ms-border: none;
      -o-border: none;
      border-width: 0;
      -webkit-border-width: 0;
      -moz-border-width: 0;
      -ms-border-width: 0;
      -o-border-width: 0;
      border-style: none;
      -webkit-border-style: none;
      -moz-border-style: none;
      -ms-border-style: none;
      -o-border-style: none;
      border-color: transparent;
      -webkit-border-color: transparent;
      -moz-border-color: transparent;
      -ms-border-color: transparent;
      -o-border-color: transparent;
      box-sizing: content-box;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      -ms-box-sizing: content-box;
      -o-box-sizing: content-box;
      outline: none;
      -webkit-outline: none;
      -moz-outline: none;
      -ms-outline: none;
      -o-outline: none;
      border: none !important;
      -webkit-border: none !important;
      -moz-border: none !important;
      -ms-border: none !important;
      -o-border: none !important;
      $no-border: none;
      border: $no-border;
      -webkit-border: $no-border;
      -moz-border: $no-border;
      -ms-border: $no-border;
      -o-border: $no-border;
    }

    img.background {
      width: 45%;
      position: absolute;
      left: 2em;
      top: 0;

      @media (max-width: 992px) {
        display: none;
      }
    }

    @media (max-width: 992px) {
      min-height: 740px;
      background-image: none;
      min-height: initial;
      margin-top: 78px;
      margin-bottom: 0px;
      padding-bottom: 60px;
    }

    .mobile-img-1 {
      display: none;

      @media (max-width: 992px) {
        display: flex;
      }
    }

    .right-side {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 50px;

      @media (max-width: 992px) {
        padding-top: 20px;
      }

      .title {
        color: black;
        @include title-large();

        @media (max-width: 992px) {
          margin-bottom: 20px;
        }

        margin-bottom: 35px;

        .g1 {
          //background: linear-gradient(
          //  90.32deg,
          //  #65dcae 14.65%,
          //  #3566f6 42.87%,
          //  #000000 82.43%
          //);
          background: black;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;

          @media (max-width: 400px) {
            font-size: 40px;
          }
        }

        .g2 {
          background: linear-gradient(to right, #64dbad, #3566f6);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 53px;

          @media (max-width: 992px) {
            font-size: 47px;
          }

          @media (max-width: 400px) {
            font-size: 40px;
          }
        }
      }

      .sup-title {
        @include paragraph-large();

        @media (max-width: 992px) {
          margin-bottom: 30px;
        }

        margin-bottom: 60px;

        .better-min {
          @include better-min;
        }
      }

      .header-button-reverse {
        @include header-button-reverse("/images/all/Header-Video-icone.png");
      }
    }

    .left-side {
      text-align: left;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  // section-parts///////////////////////////////////////////////////

  .section-parts {
    position: relative;
    background: linear-gradient(180deg, #FFFFFF 0%, #eeF2FF 50%);


    img.background {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 20%;

      @media (max-width: 992px) {
        display: none;
      }
    }

    @media (max-width: 992px) {
      // background-image: url(/images/all/Home-Section1-mobile.png);
      // background-repeat: no-repeat;
      // padding-top: 500px;
      // background-size: 100% 50%;
      // background-position-y: 0px;
    }

    // @media (max-width: 446px) {
    //   background-size: 100% 45%;
    // }
    // @media (max-width: 396px) {
    //   background-size: 100% 43%;
    // }
    .gradiant-background {
      width: 100%;
      padding-bottom: 170px;
      background: linear-gradient(180deg, #ffffff00 50%, #eef2ff 100%);

      @media (max-width: 992px) {
        padding-bottom: 0;
      }

      .flow-image {
        display: none;

        @media (max-width: 992px) {
          display: inline-block;
          margin-bottom: -85px;
        }
      }
    }

    .s2-text-holder {
      justify-content: space-between;
      align-items: center;

      @media (max-width: 992px) {
        padding-bottom: 60px;
      }

      h1 {
        font-size: 50px;
        font-weight: bold;
        line-height: 73px;
        text-align: center;
        margin-bottom: 78px;

        @media (max-width: 992px) {
          font-size: 40px;
          line-height: 59px;
          margin-bottom: 24px;
        }
      }

      .sub-text {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        //align-items: center;

        .part {
          display: flex;
          flex-direction: column;
          max-width: 250px;

          @media (max-width: 992px) {
            max-width: 69%;
          }

          h2 {
            font-size: 80px;
            font-weight: bold;
            line-height: 118px;

            @media (max-width: 992px) {
              font-size: 58px;
              line-height: 85px;
            }
          }

          h3 {
            font-size: 24px;
            font-weight: bold;
            line-height: 35px;
          }

          p {
            font-size: 20px;
            font-weight: 400;
            line-height: 29px;

            @media (max-width: 992px) {
              font-size: 18px;
              line-height: 30px;
            }
          }
        }
      }
    }
  }



  // section-save
  .section-save {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background: linear-gradient(103.48deg, #1b35db -8.94%, #3566f6 107.29%);

    .chat-background {
      width: 100%;
      background-image: url("/images/all/Home-Section3-Background.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-position-x: 0%;
      background-size: contain;

      @media (max-width: 992px) {
        background-image: url("/images/all/Home-Section3-Background-mobile.png");
        background-repeat: no-repeat;
        background-position-y: 68%;
      }
    }

    .space {
      display: flex;
      justify-content: space-around;
    }

    .text-holder {
      display: flex;
      flex-direction: column;
      width: 45%;

      justify-content: center;
      align-items: center;

      // max-width: 32%;
      @media (max-width: 992px) {
        width: 100%;
        padding-top: 45px;
      }

      .title-3 {
        display: inline;
        @include title-regular();
        margin-bottom: 35px;
      }

      .paragraph {
        @include paragraph-small();

        .bold-green {
          @include paragraph-small();
          font-weight: 800;
          color: #64dbad;

          @media (max-width: 992px) {
            font-weight: 800;
          }

          @media (max-width: 400px) {
            font-weight: 800;
          }
        }
      }
    }

    .left-side {
      margin-top: 55px;
      left: 0;
      overflow: hidden;

      img {
        max-width: 400px;
        height: auto;
      }

      @media (max-width: 992px) {
        padding-top: 0px;
        margin-top: 0;

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }

  .section-new {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 55px;
    padding-top: 80px;
    color: #070C4E;
    //background: linear-gradient(103.48deg, #1b35db -8.94%, #3566f6 107.29%);

    @media (max-width: 960px) {
      padding-top: 0px;
      padding-bottom: 60px;
    }

    .g2 {
      background: linear-gradient(to right, #64dbad, #3566f6);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 53px;

      @media (max-width: 960px) {
        font-size: 40px;
      }

      @media (max-width: 400px) {
        font-size: 33px;
      }

    }

    .space {
      display: flex;
      justify-content: space-around;
    }

    .text-holder {
      display: flex;
      flex-direction: column;
      width: 50%;

      justify-content: center;
      align-items: center;

      // max-width: 32%;
      @media (max-width: 992px) {
        width: 100%;
        padding-top: 60px;
      }

      .title-3 {
        display: inline;
        width: 100%;
        @include title-regular();
        margin-bottom: 35px;
      }

      .paragraph {
        width: 100%;
        @include paragraph-small();

        .bold-green {
          @include paragraph-small();
          font-weight: 800;
          color: #64dbad;

          @media (max-width: 992px) {
            font-weight: 800;
          }

          @media (max-width: 400px) {
            font-weight: 800;
          }
        }
      }
    }

    .left-side {
      margin-top: 0px;
      left: 0;
      overflow: hidden;

      img {
        max-width: 400px;
        height: auto;
      }

      @media (max-width: 992px) {
        padding-top: 50px;
        margin-top: 0;

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }

  .how-does-it-work {
    //background-image: linear-gradient(180deg, #FFFFFF 0%, #eeF2FF 100%) ;
    position: relative;
    width: 100%;
    //background-color: red;
    padding-top: 80px;
    padding-bottom: 170px;
    //linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #eef2ff 100%)

    @media (max-width: 992px) {
      margin-top: 80px;
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 15px;
      padding-right: 15px;
    }

    .lightble-gradiant.one {
      margin-right: -8px;
    }
  }
}

.background-mobile-only {
  display: none;

  @media (max-width: 992px) {
    display: block;
  }
}

.mobile-only {
  display: none;

  @media (max-width: 992px) {
    display: block;
    position: relative;
    //top: 20px;
  }
}

.customers {
  //From NewTechSm.scss

  h2.mini-title {
    letter-spacing: -0.01em;

    span {
      text-align: right;
      display: inline-block;
      margin: 0 12px;
      color: #294FE9;
    }

    @media (max-width: 992px) {
      text-align: center;
      letter-spacing: -0.08em;

      span {
        margin: 0 8px;
      }
    }

  }

  .savings-wrapper {
    display: flex;
    margin: 60px 0;
    justify-content: space-around;

    .savings-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      .savings {
        color: #294FE9;
      }

      .logo-wrapper {
        height: 80px;
        display: flex;
        align-items: center;

        .customers-logo {
          max-width: 160px;
        }
      }
    }

    @media (max-width: 992px) {
      flex-direction: column;
      margin: 8vh 0 0 0;

      .customers-img {
        width: 50%;
      }

      .savings-item {
        margin-bottom: 8vh;

        .logo-wrapper {
          height: 9vh;

          .customers-logo {
            max-width: 130px;
          }
        }
      }
    }
  }
}

.content-container {
  //From NewTechSm.scss
  padding: 10vh 0;
  width: 64%;
  margin: auto;

  @media (max-width: 992px) {
    width: 85%;
    padding: 8vh 0;
  }

  h1 {
    margin-bottom: 3vh;
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    text-align: justify;

    @media (max-width: 992px) {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
    }

    @media (min-width: 1700px) {
      font-weight: 700;
      font-size: 65px;
      line-height: 75px;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 53px;
    text-align: right;

  }

  h2.mini-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    text-align: justify;
    margin-bottom: 4vh;

    @media (max-width: 992px) {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      text-align: justify;
    }

  }

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: justify;

    @media (max-width: 992px) {
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
    }
  }

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: justify;

    @media (max-width: 992px) {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      text-align: justify;
    }

    @media (min-width: 1700px) {
      font-size: 30px;
      line-height: 50px;
    }
  }

}

.recommended {

  //From NewTechSm.scss
  .content-container {

    border-bottom: 1px solid black;

    h2 {

      img {
        margin: 0 10px;
        width: 140px;
      }
    }

    .recommended-logos {
      display: grid;
      grid-template-columns: 27% 27% 27%;
      grid-column-gap: 9.5%;
      // grid-row-gap: 60px;
      align-items: center;
      justify-items: center;
      justify-content: center;
    }

    @media (max-width: 992px) {

      padding: 8vh 0 10vh 0;

      .recommended-logos {
        display: grid;
        grid-template-columns: 27% 27% 27%;
        grid-column-gap: 9.5%;
        // grid-row-gap: 4vh;
        align-items: center;
        justify-items: center;
        justify-content: center;
      }

      h2 {
        letter-spacing: -0.06em;
        margin-bottom: 7vh;

        img {
          margin: 0 5px;
          width: 80px;
        }
      }
    }
  }
}

.home-container {
  .gif-video-mobile-only {
    display: none;

    @media (max-width: 992px) {
      display: block;
      width: 100%;
    }
  }
}